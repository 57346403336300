import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  _EditPlaylist,
  addScreenToPlaylist,
  deletePlaylist,
  playlistFlagOff,
  playlistScreenCheck,
  playlistToMultipleScreen,
} from "../../../../Redux/Actions/playlistAction";
import {
  getScreens,
  getScreensOnFolder,
  screenFlagOff,
} from "../../../../Redux/Actions/screenAction";
import "./playlistcard.css";
// import { ReactComponent as DeleteIcon } from "../../../../Assets/images/delete.svg";
// import { ReactComponent as CreateIcon } from "../../../../Assets/images/createicon.svg";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Apps,
  Close,
  DeleteOutlineOutlined,
  DesktopMacOutlined,
  Edit,
  ListAltOutlined,
  PlaylistAddOutlined,
} from "@mui/icons-material";
import PlaylistContentCardItem from "../content-card/PlaylistContentCardItem";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { api } from "../../../../api/api";
import Loader from "../../../../components/loader/Loader";
import MiniLoader from "../../../../components/loader/MiniLoader";
import useFilter from "../../../../hooks/useFilter";
import PlaylistContentListItem from "../content-card/PlaylistContentListItem";

function PlaylistCard(props) {
  const dispatch = useDispatch();
  const {
    screens,
    isPlaylistAssign,
    isPlaylistPermissionUpdating,
    isPlaylistPermissionUpdated,
    playlist,
    user,
    users,
    folders,
    isScreenFetch,
    isScreenNotFetch,
  } = useSelector(
    (state) => ({
      screens: state.Screen.screens,
      isPlaylistAssign: state.Playlist.isPlaylistAssign,
      isPlaylistPermissionUpdating: state.Playlist.isPlaylistPermissionUpdating,
      isPlaylistPermissionUpdated: state.Playlist.isPlaylistPermissionUpdated,
      isScreenFetch: state.Screen.isScreenFetch,
      isScreenNotFetch: state.Screen.isScreenNotFetch,
      playlist: state.Playlist.playlist,
      user: state.User.user,
      users: state.User.users,
      folders: state.Folder.folders,
    }),
    shallowEqual
  );
  const [code, setCode] = useState("");
  const [isScreenOpen, setIsScreenOpen] = useState(false);
  const [screenMacs, setScreenMacs] = useState([]);
  const [filteredScreenMacs, setFilterScreenMacs] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchVal, setSearchVal] = useState("");
  const open = Boolean(anchorEl);
  const filtered = useFilter(searchVal, screenMacs)();
  const [playlistId, setPlaylistId] = useState("");
  const [tabView, setTabView] = useState(false);
  const [listView, setListView] = useState(true);
  const [isPermissionOpen, setIsPermissionOpen] = useState(false);
  const [playlistName, setPlaylistName] = useState("");
  const [predecessor, setPredecessor] = useState([]);
  const [delList, setDelList] = useState([]);
  const [_user, setUser] = useState([]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type, obj) => {
    setAnchorEl(null);
    // setOpenProfileDialog(true);
  };
  useEffect(() => {
    if (isPlaylistAssign) {
      setIsScreenOpen(false);
      dispatch(getScreens(user?._id));
      _copyingScreens();
      //  dispatch(playlistFlagOff())
    }
  }, [isPlaylistAssign]);
  useEffect(() => {
    _copyingScreens();
    // dispatch(getScreens(user?._id))
    // console.log(props);
  }, []);
  useEffect(() => {
    if (isScreenFetch) {
      assignedScreenFetch(playlistId);
      dispatch(screenFlagOff());
    }
    if (isScreenNotFetch) {
      assignedScreenFetch(playlistId);
      dispatch(screenFlagOff());
    }
  }, [isScreenFetch, isScreenNotFetch]);
  const _copyingScreens = () => {
    let _screens = [];

    screens.forEach((s) => {
      _screens.push({
        _id: s._id,
        name: s.name,
        mac: s.mac,
        _playlistId: s?.playlist?._id,
        isChecked: false,
      });
    });
    setScreenMacs(_screens);
  };
  const assignedScreenFetch = (id) => {
    const _checkedList = screens.map((s) =>
      s.playlist?._id == id
        ? { ...s, isChecked: true }
        : { ...s, isChecked: false }
    );
    setScreenMacs(_checkedList);
  };
  // useEffect(()=>{
  //   setScreenMacs(filteredScreenMacs);
  // },[searchVal])
  // useEffect(()=>{
  //   let _macs=[]
  //  playlist.mac_list.forEach(m=>{
  //    _macs.push()
  //  })
  // },[props.play._id,isPlaylistAssign])

  const onGetScreenAssign = (e, position) => {
    const { value, checked, name } = e.target;
    // if(position=='unfiltered'){
    if (checked) {
      setScreenMacs((prev) => [
        ...prev.map((s) => (s.name == name ? { ...s, isChecked: true } : s)),
      ]);
    } else {
      setScreenMacs((prev) => [
        ...prev.map((s) => (s.name == name ? { ...s, isChecked: false } : s)),
      ]);
    }
    // }
  };
  const onAssignPlaylist = async () => {
    try {
      let mac_list = [];
      for (let i in filtered) {
        if (filtered[i].isChecked) {
          mac_list.push(filtered[i].mac);
        }
      }
      dispatch(playlistToMultipleScreen(props.play._id, mac_list));
    } catch (error) {
      console.log(error.message);
    }
  };
  const onSearchFilter = (e) => {
    const { value } = e.target;
    setSearchVal(value);
  };
  const searchCallBack = useCallback(onSearchFilter, [searchVal]);

  const onOpenAssignScreen = (id) => {
    setPlaylistId(id);
    assignedScreenFetch(id);
    // const _checkedList=screenMacs.map(s=>s._playlistId==id ? {...s,isChecked:true} : s);
    // setScreenMacs(_checkedList)
    setIsScreenOpen(true);
  };
  const onGetFolderScreen = useCallback((e) => {
    dispatch(getScreensOnFolder(e.target.value));
    // _copyingScreens();
  }, []);
  const onClickTabView = () => {
    setTabView(true);
    setListView(false);
  };
  const onClickListView = () => {
    setTabView(false);
    setListView(true);
  };

  const onGetValue = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setUser(
        _user.map((u) => (u._id == value ? { ...u, isAssigned: true } : u))
      );
      setPredecessor((prev) => [...prev, value]);
      setDelList((prev) => [prev.filter((d) => d != value)]);
    } else {
      setUser(
        _user.map((u) => (u._id == value ? { ...u, isAssigned: false } : u))
      );
      setPredecessor((prev) => prev.filter((p) => p != value));
      setDelList((prev) => [...prev, value]);
    }
  };

  const onSelectAll=(e)=>{
    const { checked, value } = e.target;
    if (checked) {
      setUser(
        _user.map((u) => ({ ...u, isAssigned: true }))
      );
      setPredecessor(_user.map(u=>u._id));
      setDelList([]);
    } else {
      setUser(
        _user.map((u) => ( { ...u, isAssigned: false }))
      );
      setPredecessor([]);
      setDelList(_user.map(u=>u._id));
    }
  }

  const onHandlePermission = useCallback((index) => {
    // if (isEditMode) {
    // setFolderName(_folders?.name);
    let __user = [];
    let ___user = {};
    const _playlistIndex = playlist.findIndex((p) => p._id == index);
    // console.log(_playlist[_playlistIndex],index)
    playlist[_playlistIndex]?.predecessor?.forEach((user) => {
      ___user[user._id] = user.username;
    });
    users.forEach((user) => {
      if (___user[user._id] == user?.username) {
        __user.push({
          _id: user?._id,
          username: user?.username,
          role: user.role,
          isAssigned: true,
        });
      } else {
        __user.push({
          _id: user._id,
          username: user.username,
          role: user.role,
          isAssigned: false,
        });
      }
    });
    // console.log(__user)
    const _pre_ids = __user.filter((u) => u.isAssigned == true);
    setPredecessor(_pre_ids.map((u) => u._id));
    setUser(__user);
    // }
    //  else {
    //   setUser(
    //     users.map((u) => ({
    //       _id: u._id,
    //       username: u.username,
    //       role: u.role,
    //       isAssigned: false,
    //     }))
    //   );
    // }
    setIsPermissionOpen(true);
    setPlaylistName(props.play.name);
  }, []);
  const onEditPlaylist = useCallback(() => {
    dispatch(
      _EditPlaylist(props.play._id, {
        name: playlistName,
        predecessor,
        delList,
      })
    );
    setIsPermissionOpen(false);
  }, [playlistName, predecessor, delList]);
  return (
    <>
      <div className="playlist-list-cards">
        <div className="playlist-list-cards-top">
          <div className="playlist-list-cards-top-btns">
            <div>
              <Apps onClick={onClickTabView} style={{ cursor: "pointer" }} />
              <ListAltOutlined
                onClick={onClickListView}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div>{props.play.name}</div>
            <div>
              <MoreVertIcon
                sx={{ fontSize: "25px", color: "var(--primary-color)" }}
                onClick={handleClick}
                aria-controls={open ? "content-menu" : undefined}
                aria-haspopup={"true"}
                aria-expanded={open ? "true" : undefined}
                id="content-menu-btn"
              />
              <Menu
                sx={{ zIndex: "10000000" }}
                id={"content-menu"}
                anchorEl={anchorEl}
                open={open}
                MenuListProps={{
                  "aria-labelledby": "content-menu-btn",
                }}
                onClose={handleClose}
                keepMounted
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                {user.role == "user" ? (
                  <></>
                ) : (
                  <MenuItem
                    onClick={() => onHandlePermission(props.play._id)}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Edit
                      sx={{ color: "var(--primary-color)", cursor: "pointer" }}
                    />
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    handleClose();
                    props.deleteWholePlaylist(
                      props.play._id,
                      props.play?.activescreen?.mac
                    );
                  }}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <DeleteOutlineOutlined
                    sx={{ color: "var(--primary-color)", cursor: "pointer" }}
                  />
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
        <div className="playlist-list-cards-body">
          {listView && (
            <div className="playlist-list-cards-body-wrap">
              {props.play.content.map((v, index) => (
                <PlaylistContentListItem
                  key={index}
                  content={v}
                  deleteContent={props.deleteContent}
                  addSchedule={props.addSchedule}
                  updateTimeHandler={props.updateTimeHandler}
                  playID={props.play._id}
                />
              ))}
            </div>
          )}
          {tabView && (
            <div className="playlist-card-body-tab">
              {props.play.content.map((v, index) => (
                <PlaylistContentCardItem
                  key={index}
                  content={v}
                  deleteContent={props.deleteContent}
                  addSchedule={props.addSchedule}
                  updateTimeHandler={props.updateTimeHandler}
                  playID={props.play._id}
                />
              ))}
            </div>
          )}
        </div>
        <div className="playlist-list-cards-bottom flex justify-content-space-between alignItemCenter">
          <div className="add-content-playlist flex justifyContentCenter alignItemCenter">
            <PlaylistAddOutlined
              style={{ color: "var(--primary-color)", cursor: "pointer" }}
              onClick={() =>
                props.addContentModel(
                  props.play._id,
                  props.play?.activescreen?.mac
                )
              }
            />
            <span>Create</span>
          </div>
          {/* <Box>{props.play.name}</Box> */}
          <div className="delete-playlist flex justifyContentCenter alignItemCenter">
            {/* <MoreVertIcon
              sx={{ fontSize: "25px", color: "var(--primary-color)" }}
              onClick={handleClick}
              aria-controls={open ? "content-menu" : undefined}
              aria-haspopup={"true"}
              aria-expanded={open ? "true" : undefined}
              id="content-menu-btn"
            /> */}
            {/* <Menu
              sx={{ zIndex: "10000000" }}
              id={"content-menu"}
              anchorEl={anchorEl}
              open={open}
              MenuListProps={{
                "aria-labelledby": "content-menu-btn",
              }}
              onClose={handleClose}
              keepMounted
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  props.deleteWholePlaylist(
                    props.play._id,
                    props.play?.activescreen?.mac
                  );
                }}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <DeleteOutlineOutlined
                  sx={{ color: "var(--primary-color)", cursor: "pointer" }}
                />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  onOpenAssignScreen(props.play._id);
                }}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <DesktopMacOutlined />
              </MenuItem>
            </Menu> */}
          </div>
        </div>
      </div>
      {/* {console.log(filtered)} */}

      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={isPermissionOpen}
        onClose={() => {
          setIsPermissionOpen(false);
        }}
      >
        <DialogTitle>Permission</DialogTitle>
        <DialogContent>
          <div style={{ margin: "20px 0" }}>
            <div
              style={{
                display: "flex",
                gap: "10px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                variant="standard"
                value={playlistName}
                onChange={(e) => setPlaylistName(e.target.value)}
                label="Title"
                fullWidth
                sx={{
                  label: {
                    color: "blue",
                    fontSize: "14px",
                    color: "#92959E",
                  },
                }}
              />

              <Box
                sx={{
                  maxHeight: "40vh",
                  width: "20dvw",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  overflowY: "auto",
                }}
              >
                <Box
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  gap={"10px"}
                >
                  <input
                    type={"checkbox"}
                   onChange={onSelectAll}
                    style={{ height: "20px", width: "20px" }}
                  />
                  <span>Select all</span>
                </Box>
                {_user?.map((_, _index) => (
                  <>
                    <Box
                      display={"flex"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      gap={"10px"}
                    >
                      <input
                        type={"checkbox"}
                        name={_?.username}
                        value={_?._id}
                        onChange={(e) => onGetValue(e)}
                        // onChange={(e)=>onGetScreenAssign(e,'unfiltered')}
                        checked={_.isAssigned}
                        style={{ height: "20px", width: "20px" }}
                      />
                      <span style={{ display: "flex", gap: "5px" }}>
                        {_?.username}
                        {<span>({_?.role})</span>}
                      </span>
                    </Box>
                  </>
                ))}
              </Box>

              <button
                style={{ marginTop: "40px" }}
                className="btn-save-content"
                onClick={onEditPlaylist}
              >
                Create
              </button>
              {isPlaylistPermissionUpdating && <MiniLoader />}
              {/* {
                isPlaylistPermissionUpdated && setIsPermissionOpen(false)
              } */}
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={isScreenOpen}
        maxWidth={"sm"}
        onClose={() => setIsScreenOpen(false)}
        sx={{
          zIndex: "100000000000",
        }}
      >
        <DialogTitle display={"flex"} justifyContent={"space-between"}>
          <span>Assign to screens</span>{" "}
          <Close
            onClick={() => setIsScreenOpen(false)}
            sx={{ color: "red", cursor: "pointer" }}
          />{" "}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              height: "5vh",
              width: "100%",
              margin: "10px 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <select
              onChange={onGetFolderScreen}
              style={{ height: "100%", width: "100%" }}
            >
              {folders?.map((_, _index) => (
                <option key={_index} value={_?._id}>
                  {_?.name}
                </option>
              ))}
            </select>
          </Box>
          <Box
            sx={{
              height: "5vh",
              width: "100%",
              margin: "10px 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextField
              value={searchVal}
              onChange={searchCallBack}
              variant={"outlined"}
              label={"Search..."}
              fullWidth
            />
          </Box>
          <Box
            sx={{
              maxHeight: "40vh",
              width: "20dvw",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              overflowY: "auto",
            }}
          >
            <Box
              display={"flex"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              gap={"10px"}
            >
              <input
                type={"checkbox"}
                onChange={(e) =>
                  e.target.checked
                    ? setScreenMacs((prev) => [
                        ...prev?.map((s) => ({ ...s, isChecked: true })),
                      ])
                    : setScreenMacs((prev) => [
                        ...prev?.map((s) => ({ ...s, isChecked: false })),
                      ])
                }
                style={{ height: "20px", width: "20px" }}
              />
              <span>Select all</span>
            </Box>
            {filtered?.map((_, _index) => (
              <>
                <Box
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  gap={"10px"}
                >
                  <input
                    type={"checkbox"}
                    name={_?.name}
                    value={_?.mac}
                    onChange={(e) => onGetScreenAssign(e, "unfiltered")}
                    checked={_.isChecked}
                    style={{ height: "20px", width: "20px" }}
                  />
                  <span>{_?.name}</span>
                </Box>
              </>
            ))}
          </Box>
          <Box
            sx={{
              height: "10vh",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              onClick={() => {
                onAssignPlaylist();
              }}
              variant={"contained"}
              sx={{
                background: "var(--primary-color)",
                textTransform: "capitalize",
              }}
              className="multiple--assign--btn"
            >
              Assign
            </Button>
            {isPlaylistAssign && <MiniLoader />}
            {/* <span>Total Screens {screens.length}</span> */}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default PlaylistCard;
