import {
  ADD_CONTENT_VALUE,
  COPY_CONTENT_SUCCESS,
  DELETE_CONTENT_VALUE,
  EMPTY_CONTENT_FORM,
} from "../constant";
const initial = {
  contentForm: { id: "", value: [] },
  allContent: [],
  isContentAvailable: false,
};
export const formReducer = (state = initial, action) => {
  switch (action.type) {
    case COPY_CONTENT_SUCCESS:
      return {
        ...state,
        allContent: [...action.payload],
      };
    case ADD_CONTENT_VALUE:
      const findAddContentIndex = state.allContent.findIndex(
        (c) => c.id === action.payload.data.id
      );
      state.allContent[findAddContentIndex] = { ...action.payload.data, selected: true };
      return {
        ...state,
        contentForm: {
          id: action.payload.id,
          value: [...state.contentForm.value, { ...action.payload.data }],
        },
        allContent: state.allContent,
        isContentAvailable: true,
      };
    case DELETE_CONTENT_VALUE:
      const findDelContentIndex = state.allContent.findIndex((c) => c.id === action.payload.id);
      state.allContent[findDelContentIndex] = {
        ...state.allContent[findDelContentIndex],
        selected: false,
      };
      return {
        ...state,
        contentForm: {
          id: action.payload.pid,
          value: state.contentForm.value.filter((f) => f.id !== action.payload.id),
        },
        allContent: state.allContent,
        isContentAvailable: true,
      };
    case EMPTY_CONTENT_FORM:
      return {
        ...state,
        contentForm: { id: "", value: [] },
        isContentAvailable: false,
      };
    default:
      return state;
  }
};
