import React, { useCallback, useEffect, useRef, useState } from "react";
import "./editor.css";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import PanoramaOutlinedIcon from "@mui/icons-material/PanoramaOutlined";
import OndemandVideoOutlinedIcon from "@mui/icons-material/OndemandVideoOutlined";
import RoundedCornerOutlinedIcon from "@mui/icons-material/RoundedCornerOutlined";
import Design from "./components/Design/Design";
import { fabric } from "fabric";
import Text from "./components/Text/Text";
import Picture from "./components/Picture/Picture";
import Video from "./components/Video/Video";
import Shape from "./components/Shape/Shape";
import EditorOps from "./EditorOps";
import ShapesProperty from "./components/Shape/ShapesProperty";
import CanvaProperty from "./CanvaProperty";
import { useDispatch, useSelector } from "react-redux";
import TextTools from "./components/Text/TextTools";
import { resetTextFontIsChanged } from "../Redux/Actions/EditorAction";
const properties = {
  default: CanvaProperty,
  Shapes: ShapesProperty,
  TextTool: TextTools,
};
export default function DesignEditor() {
  const { _textProps, _fontColor, isChanged } = useSelector(
    (state) => state.Editor
  );
  const dispatch = useDispatch();
  const [layers, setLayers] = useState([]);
  const [objecKey, setObjectKey] = useState("default");
  const canvasRef = useRef(null);
  const [type, setType] = useState("Design");
  const [file, setFile] = useState("");
  const [height, setHeight] = useState(800);
  const [width, setWidth] = useState(500);
  const [pan, setPan] = useState(false);
  const [id, setId] = useState("");
  const [_textAlign, setTextAlign] = useState("");
  const [length, setLength] = useState({
    height: "",
    width: "",
  });
  const [canvaColor, setCanvaColor] = useState("white");
  const PropComponent = properties[objecKey];
  useEffect(() => {
    const canvas = new fabric.Canvas("canvas", {
      name: "canva-main",
      height: 800,
      width: 500,
      backgroundColor: "white",
      selection: true,
    });
    canvasRef.current = canvas;
    canvas.on("mouse:down", (e) => {
      // console.log('hello from canvas',e)
      setObjectKey("default");
    });
    canvas.requestRenderAll();
    return () => {
      canvas.dispose();
    };
  }, []);
  // useEffect(()=>{

  // },[isChanged])
  const panFunction = () => {
    if (canvasRef.current) {
      canvasRef.current.on("mouse:up", (e) => {
        console.log(e.e);
        const delta = new fabric.Point(e.e.movementX, e.e.movementY);
        canvasRef.current.relativePan(delta);
        canvasRef.current.renderAll();
      });
    }
  };
  const DesignCatagory = (name) => {
    switch (name) {
      case "Design":
        return (
          <Design
            onGetImage={(f) => getImageFromLocalComputer(f)}
            layer={layers}
          />
        );
        break;
      case "Text":
        return (
          <Text
            onSelectText={onSelectText}
            onAlignLeft={onAlignLeft}
            onAlignRight={onAlignRight}
          />
        );
        break;
      case "Picture":
        return <Picture onGetImage={(f) => getImageFromLocalComputer(f)} />;
        break;
      case "Video":
        return <Video onGetVideo={(v) => getVideoFromLocalComputer(v)} />;
        break;
      case "Shape":
        return (
          <Shape
            onDrawShapes={onDrawShapes}
            onAddEmojiToCanva={onAddEmojiToCanva}
          />
        );
        break;
      default:
        return "Invalid";
    }
  };
  const onDrawShapes = (name) => {
    let shape;
    if (canvasRef.current) {
      if (name === "Rect") shape = new EditorOps().createRect();
      if (name === "Circle") shape = new EditorOps().createCircle();
      if (name === "Line") shape = new EditorOps().createLine();
      if (name === "Star") shape = new EditorOps().createPolygone();
      if (name === "Triangle") shape = new EditorOps().createTriangle();
      setLayers((prev) => [...prev, shape]);
      canvasRef.current.add(shape);
      canvasRef.current.on("mouse:down", (e) => {
        if (e?.target?.name == "Shape") {
          setId(e.target?.id);
          setObjectKey("Shapes");
          console.log(e.target?.id);
        }
      });
      canvasRef.current.requestRenderAll();
    }
  };
  const onAddEmojiToCanva = (emoji) => {
    const _emoji = new EditorOps().addEmojiToCanvas(emoji);
    setLayers((prev) => [...prev, _emoji]);
    canvasRef.current.add(_emoji);
    canvasRef.current.on("mouse:down", (e) =>
      console.log(e.target, e.target?.type)
    );
    canvasRef.current.requestRenderAll();
  };
  const getImageFromLocalComputer = (f) => {
    const reader = new FileReader();
    reader.readAsDataURL(f);
    reader.onload = function () {
      if (canvasRef.current) {
        new fabric.Image.fromURL(reader.result, (img) => {
          // const maxWidth = 400;
          // const maxHeight = 300;

          // Calculate dimensions while maintaining aspect ratio

          img.set({
            // width: maxWidth,
            // height: maxHeight,
            paintFirst: "fill",
            cornerColor: "black",
          });

          setLayers((prev) => [...prev, img]);
          // const scaledImg = scaleImageToFit(img, canvasRef.current.width, canvasRef.current.height);
          canvasRef.current.add(img);
          canvasRef.current.requestRenderAll();
        });
      }
    };
  };

  function getVideoElement(url) {
    var videoE = document.createElement("video");
    videoE.width = 530;
    videoE.height = 298;
    videoE.muted = true;
    videoE.crossOrigin = "anonymous";
    var source = document.createElement("source");
    source.src = url;
    source.type = "video/mp4";
    videoE.appendChild(source);
    return videoE;
  }

  const getVideoFromLocalComputer = (v) => {
    const reader = new FileReader();
    reader.readAsDataURL(v);
    reader.onload = function () {
      if (canvasRef.current) {
        const videoE = getVideoElement(reader.result);
        const vid = new fabric.Image(videoE, {
          left: 200,
          top: 300,
          angle: -15,
          originX: "center",
          originY: "center",
          cornerColor: "black",
          objectCaching: false,
        });
        setLayers((prev) => [...prev, vid]);
        canvasRef.current.add(vid);
        // videoEl.load();
        vid.getElement().play();
        canvasRef.current.requestRenderAll();
      }
    };
  };

  const onAlignLeft = (align) => {
    setTextAlign(align);
  };
  const onAlignRight = (align) => {
    setTextAlign(align);
  };
  const onSelectText = () => {
    if (canvasRef.current) {
      const text = new EditorOps().addTextbox();
      const shadow=new EditorOps().addTextShadow();
      text.set("shadow",shadow);
      setLayers((prev) => [...prev, text]);
      canvasRef.current.add(text);
      canvasRef.current.on("mouse:down", (e) => {
        if (e.target?.name === "Textbox") {
          setObjectKey("TextTool");
          setId(e.target?.id);
        }
      });
      canvasRef.current.requestRenderAll();
    }
  };

  const onSetHeightWidthOfCanva = (obj) => {
    if (canvasRef.current) {
      setHeight(obj.height);
      setWidth(obj.width);
      const height = canvasRef.current.getHeight();
      const width = canvasRef.current.getWidth();
      const newHeight = height + (obj.height - height);
      const newWidth = width + (obj.width - width);
      canvasRef.current.setHeight(newHeight);
      canvasRef.current.setWidth(newWidth);
      canvasRef.current.requestRenderAll();
    }
  };
  const onGetChangeCanvaBackgroundColor = (color) => {
    if (canvasRef.current) {
      setCanvaColor(color.hex);
      canvasRef.current.set("backgroundColor", color);
      canvasRef.current.requestRenderAll();
    }
  };
  const getLayers = () => {
    console.log(canvasRef.current.getObjects());
  };
  const onChangeTextFontColor = (_color) => {
    if (canvasRef.current) {
      const _textbox = layers.filter((l) => l.id == id);
      _textbox[0]?.set(
        "fill",
        `rgba(${_color.r},${_color.g},${_color.b},${_color.a})`
      );
      canvasRef.current.requestRenderAll();
    }
  };
  const onChangeTextFontSize = (_size) => {
    if (canvasRef.current) {
      const _textbox = layers.filter((l) => l.id == id);
      _textbox[0]?.set("fontSize", _size);
      canvasRef.current.requestRenderAll();
    }
  };
  const onChangeTextFontTransparency = (_value) => {
    console.log(_value);
  };
  const onChangeColorCompleteOfShape=(color)=>{
    if(canvasRef.current){
      const _shape=layers.filter(s=>s.id==id);
      _shape[0].set("fill",color);
      canvasRef.current.requestRenderAll();
    }
  }
  const onChangeBorderColorCompleteOfShape=(color)=>{
    if(canvasRef.current){
      const _shape=layers.filter(s=>s.id==id);
      _shape[0].set("stroke",color);
      canvasRef.current.requestRenderAll();
    }
  }
  const onChangeBorderThicknessOfShape=(_value)=>{
    // console.log(_value)
      if(canvasRef.current){
        const _shape=layers.filter(s=>s.id==id);
        _shape[0].set("strokeWidth",_value);
        canvasRef.current.requestRenderAll();
      }
  }
  const onChangeBorderRoundedOfShape=(_value)=>{
    if(canvasRef.current){
      const _shape=layers.filter(s=>s.id==id);
        _shape[0].set("rx",_value);
        _shape[0].set("ry",_value);
        canvasRef.current.requestRenderAll();
    }
  }
  return (
    <>
      <div className="editor-container">
        <header className="editor-header">
          <div className="header-left">
            <h3>SalarSync</h3>
          </div>
          <div className="header-right">
            <PropComponent
              canvaProps={{
                height,
                width,
                canvaColor,
                onGetChangeCanvaBackgroundColor: (color) =>
                  onGetChangeCanvaBackgroundColor(color),
              }}
              onSetLengthOfCanvas={(obj) => onSetHeightWidthOfCanva(obj)}
              textProps={{
                onChangeTextFontColor: onChangeTextFontColor,
                onChangeTextFontSize: onChangeTextFontSize,
                onChangeTextFontTransparency: onChangeTextFontTransparency,
              }}
              shapeProps={{
                onChangeColorCompleteOfShape:onChangeColorCompleteOfShape,
                onChangeBorderColorCompleteOfShape:onChangeBorderColorCompleteOfShape,
                onChangeBorderThicknessOfShape:onChangeBorderThicknessOfShape,
                onChangeBorderRoundedOfShape:onChangeBorderRoundedOfShape
              }}
            />
          </div>
          {/* <button onClick={() => onHeight(10)}>Height+</button>
          <button onClick={() => onWidth(10)}>Width+</button>
          <button onClick={() => panFunction()}>pan</button>
          <button onClick={() => getLayers()}>Layers</button>
          <button onClick={()=>console.log(canvasRef.current.toJSON())}>json</button>
          <button onClick={()=>canvasRef.current.loadFROMJSON()}></button> */}
          {/* <button onClick={()=>console.log(JSON.stringify(canvasRef.current.toJSON()))}>json</button> */}
        </header>
        <div className="editor-main-body">
          <div className="editor-side-bar">
            <div className="tabs">
              <div className="tab-menu" onClick={() => setType("Design")}>
                <DesignServicesIcon />
                <span>Design</span>
              </div>
              <div className="tab-menu" onClick={() => setType("Text")}>
                <FormatColorTextIcon />
                <span>Text</span>
              </div>
              <div className="tab-menu" onClick={() => setType("Picture")}>
                <PanoramaOutlinedIcon />
                <span>Picture</span>
              </div>
              <div className="tab-menu" onClick={() => setType("Video")}>
                <OndemandVideoOutlinedIcon />
                <span>Video</span>
              </div>
              {/* <div className="tab-menu">
                <OndemandVideoOutlinedIcon />
                <span>Video</span>
              </div> */}
              <div className="tab-menu" onClick={() => setType("Shape")}>
                <RoundedCornerOutlinedIcon />
                <span>Shape</span>
              </div>
            </div>
            <div className="tabs-panel">{DesignCatagory(type)}</div>
          </div>
          <div className="editor-body">
            <video
              id={"video"}
              style={{ display: "none" }}
              height="260"
              width="380"
            ></video>
            <canvas ref={canvasRef} id={"canvas"}></canvas>
          </div>
        </div>
      </div>
    </>
  );
}
