import {
  SET_BACKGROUND_COLOR_OF_CANVA,
  SET_LENGTH_OF_CANVA,
  SET_TEXT_FONT_COLOR,
} from "../constant";

const initial = {
  _canvaProps: {
    height: 800,
    width: 500,
    canvaColor: "white",
  },
  _fontColor: "black",
  isChanged: false,
  _textProps: {
    fontColor: {
      r: 0,
      g: 0,
      b: 0,
      a: 1,
    },
  },
  _shapeProps:{
    height:0,
    width:0,
    borderColor:'black',
    bgColor:'gray'
  }
};

export const EditorReducer = (state = initial, action) => {
  switch (action.type) {
    case "Reset": {
      return {
        ...state,
        isChanged: false,
      };
    }
    case SET_LENGTH_OF_CANVA:
      return {
        ...state,
        _canvaProps: {
          ...state._canvaProps,
          height: action.payload.height,
          with: action.payload.width,
        },
      };
    case SET_BACKGROUND_COLOR_OF_CANVA:
      return {
        ...state,
        _canvaProps: {
          ...state._canvaProps,
          canvaColor: action.payload.canvaColor,
        },
      };
    case SET_TEXT_FONT_COLOR:
      console.log("inside reducer=", action.payload);
      return {
        ...state,
        _fontColor: action.payload,
        isChanged: true,
        _textProps: {
          ...state._textProps,
          fontColor: {
            r: action.payload.r,
            g: action.payload.g,
            b: action.payload.b,
            a: action.payload.a,
          },
        },
      };
    default:
      return state;
  }
};
