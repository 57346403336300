import { DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import img from "../../../../Assets/images/Rainy.jpg";
import icon from "../../../../Assets/icons/cloud.png";
import sunIcon from '../../../../Assets/icons/sun.png';
import WeatherGlassmorph from "../../component/WeatherThemes/Theme-1/WeatherGlassmorph";
import AutoSuggestion from "../../component/AutoSuggestion";
import cities from '../../../../api/cities500.json';
import axios from "axios";
import clouds from '../../../../Assets/images/Cloudy.jpg';
import Clear from '../../../../Assets/images/Clear.jpg'
import Haze from '../../../../Assets/images/Sunny.jpg'
function WeatherWidget({ onClose }) {
  const [city,setCity]=useState('')
  const [weatherData,setWeatherData]=useState({});
  const bgDictionary={
       Clouds:clouds,
       Clear,
       Haze
  }
  useEffect(()=>{
    if(city=='') return;
    getForcast({city:`${city}`,unit:'metric',appId:'637cd51f54778ca88339846c4d0caaa7',hostName:'https://api.openweathermap.org'});
  },[city])
  const getForcast=async (obj)=>{
     axios.get(
      `${obj.hostName}/data/2.5/weather?q=${obj.city}&units=${obj.unit}&APPID=${obj.appId}`
    ).then(res=>{
      // console.log('first-',res)
      setWeatherData({name:res.data?.name,temp:res.data?.main?.temp,wind:res.data?.wind?.speed,humidity:res.data?.main?.humidity,weather:res.data?.weather[0]?.main,icon:res.data.weather[0].icon})
      axios.get(
        `${obj.hostName}/data/2.5/forecast?lat=${res.data.coord.lat}&lon=${res.data.coord.lon}&units=${obj.unit}&APPID=${obj.appId}`
      ).then(resp=>{
        const filteredForecast = filterForecastByFirstObjTime(resp.data?.list);
        setWeatherData((prev)=>({...prev,list:filteredForecast}))
        // console.log(resp);
      })
    });
    // const request = await axios.get(
    //   `${obj.hostName}/data/2.5/forecast?lat=${obj.lat}&lon=${obj.lon}&units=${obj.unit}&APPID=${obj.appId}`
    // )
    // const response = await request.data;
  }
  const filterForecastByFirstObjTime = (forecastData) => {
    if (!forecastData) {
      return [];
    }

    const firstObjTime = forecastData[0].dt_txt.split(" ")[1];
    let filtered= forecastData.filter((data) => data.dt_txt.endsWith(firstObjTime));
    setWeatherData((prev)=>({...prev,list:filtered}))
    return filtered;
  };

// console.log(filteredForecast)
  return (
    <>
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "3px solid var(--primary-color)",
          fontSize: "18px",
          fontWeight: 700,
        }}
      >
        Weather Widget
      </DialogTitle>
      <AutoSuggestion placeholder="Search city by Name..." data={cities} onGetValue={(e)=>setCity(e)}  />
      <div style={{ height: "70vh", width: "63vw", display: "flex" }}>
        <div
          style={{
            height: "100%",
            width: "80%",
            background: `url(${bgDictionary[weatherData.weather]})`,
            backgroundSize: "cover",
            backgroundRepeat:'no-repeat',
            display: "flex",
            flex:1
          }}
        >
         <WeatherGlassmorph
           weather={weatherData}
         />
        </div>
        {/* <div style={{ height: "100%", width: "20%" }}>themes</div> */}
      </div>
    </>
  );
}

export default WeatherWidget;
