import axios from 'axios';
export const baseApi=`${process.env.REACT_APP_URL_API}`;
export const imgUrl=`${process.env.REACT_APP_URL_IMAGE}`;
// export const baseApi='https://signage.leedoit.com/api';
// export const imgUrl='https://signage.leedoit.com'

export const api = axios.create({
    baseURL:baseApi,
    withCredentials:true,
    // headers:{
    //     'Content-type':['multipart/form-data','application/json'],
    //     Accept:'application/json'
    // }
})