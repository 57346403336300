import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  CloudCircleOutlined,
  ConstructionOutlined,
  DeleteOutlineOutlined,
  LanguageOutlined,
  LockClockOutlined,
  RssFeedOutlined,
} from "@mui/icons-material";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  playlistContentSort,
  updateImageResolution,
} from "../../../../Redux/Actions/playlistAction";


function PlaylistContentListItem(props) {
  const { isSorted } = useSelector((state) => state.Playlist);
  const [pageNumber, setPageNumber] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const ref = useRef(null);
  const [startIndex, setStartIndex] = useState("");
  const [lastIndex, setLastIndex] = useState("");
  const [playlistId, setPlaylistId] = useState("");
  const [contentId, setContentId] = useState("");
  const [imageResolutionOpen, setImageResolutionOpen] = useState(false);
  const [form, setForm] = useState({
    shrink: false,
    stretch: false,
    x: 0,
    y: 0,
  });
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type, obj) => {
    setAnchorEl(null);
  };

  const handleSorting = (e) => {
    const _first = e.dataTransfer.getData("firstIndex");
    dispatch(
      playlistContentSort({
        playlistId: props.playID,
        from: _first,
        to: lastIndex,
      })
    );

    //toast.success("Playlist sorted successfully.");
  };
  const FirstIndex = (e, id) => {
    e.stopPropagation();
    e.dataTransfer.setData("firstIndex", id);
    //setStartIndex(id)
  };
  const LastIndex = (e, id) => {
    e.preventDefault();
    setLastIndex(id);
    // setLastIndex(id)
  };

  const onOpenImagesResolutionProps = (pId, cId) => {
    setForm({
      shrink: props.content.shrink ?? false,
      stretch: props.content.stretch ?? false,
      x: props.content.x ?? 0,
      y: props.content.y ?? 0,
    });
    setPlaylistId(pId);
    setContentId(cId);
    setImageResolutionOpen(true);
  };
  const onClose = () => {
    setImageResolutionOpen(false);
    setForm({
      shrink: false,
      stretch: false,
      x: 0,
      y: 0,
    });
  };
  const onUpdateImageResolution = () => {
    dispatch(
      updateImageResolution({
        playlistId,
        contentId,
        shrink: form.shrink,
        stretch: form.stretch,
        x: form.x,
        y: form.y,
      })
    );
    setImageResolutionOpen(false);
  };
  const onGetRadio = (e) => {
    const { name, id } = e.target;
    if (id == "shrink") {
      setForm((prev) => ({ ...prev, [id]: true, stretch: false }));
    }
    if (id == "stretch") {
      setForm((prev) => ({ ...prev, [id]: true, shrink: false }));
    }
  };
  return (
    <>
      <div
        className="playlist-list-cards-view"
        draggable
        onDragStart={(e) => FirstIndex(e, props.content._id)}
        onDragEnter={(e) => {
          e.stopPropagation();
          //  LastIndex(props.content._id)
        }}
        onDragOver={(e) => LastIndex(e, props.content._id)}
        // onDragEnd={(e)=>}
        onDrop={(e) => handleSorting(e)}
      >
        <div className="list-card-view-tab">
          {props.content.type === "image" && (
            <img
              src={`${props.content.url}`}
              alt=""
              style={{ height: "90%", width: "90%" }}
            />
          )}
          {props.content.type === "video" && (
            <video
              src={`${props.content.url}`}
              controls
              style={{ height: "90%", width: "90%" }}
            />
          )}
          {props.content.type === "url" && (
            <div
              style={{
                height: "100px",
                width: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <LanguageOutlined
                sx={{
                  fontSize: "17px",
                  height: "60%",
                  width: "60%",
                  // color:"var(--primary-color)"
                }}
              />
            </div>
          )}
          {
            props.content.type === "application" && (
              <div
                style={{
                  height: "100px",
                  width: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <PictureAsPdfOutlinedIcon
                  sx={{
                    height: "60%",
                    width: "60%",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    height: "20px",
                    width: "150px",
                    background: "rgba(255,0,16,.2)",
                    borderRadius: "5px",
                    bottom: 10,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span>{props.content?.name?.substring(0, 10)}</span>
                </div>
              </div>
            )
            // <Document
            // file={`${imgUrl}/${props.content?.url}`}
            //   onLoadError={(error) => console.log("failed=", error)}
            //   onLoadSuccess={({ numPages }) => setPageNumber(numPages)}
            // >
            //   <Page  pageNumber={pageNumber}
            //     height={'130'}
            //     width={'130'}
            //     renderTextLayer={false}
            //     renderAnnotationLayer={false}  />
            // </Document>
          }
          {(props.content.type === "widget" ||
            props.content.type == "weather" ||
            props.content.type == "clock" ||
            props.content.type == "rss") && (
            <div
              style={{
                height: "100px",
                width: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              {props.content.type == "weather" && (
                <CloudCircleOutlined
                  style={{ height: "60%", width: "60%" }}
                  alt=""
                />
              )}
              {props.content.type == "clock" && (
                <LockClockOutlined
                  style={{ height: "60%", width: "60%" }}
                  alt=""
                />
              )}
              {props.content.type == "rss" && (
                <RssFeedOutlined
                  style={{ height: "60%", width: "60%" }}
                  alt=""
                />
              )}
              {/* <img
                  src={`${props.content?.widgetIcon}`}
                    style={{
                      height: "60%",
                      width: "60%",
                    }}
                  /> */}
              <div
                style={{
                  position: "absolute",
                  height: "20px",
                  width: "150px",
                  background: "rgba(255,0,16,.2)",
                  borderRadius: "5px",
                  bottom: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>{props.content?.name?.substring(0, 10)}</span>
              </div>
            </div>
          )}
        </div>
        <div className="list-card-view-tab">
          <span>{props.content.name?.substr(0, 10)}</span>
        </div>
        <div className="list-card-view-tab">
          <div
            // onClick={()=>alert('hello')}
            onClick={() =>
              props.updateTimeHandler(
                props.playID,
                props.content._id,
                props.content.duration
                // play?.activescreen?.mac
              )
            }
            className={`${"timer-background"} timer flex justifyContentCenter alignItemCenter`}
          >
            {/* {props.content.type !== "video" && ( */}
            <>
              <AccessTimeIcon
                sx={{
                  marginRight: "5px",
                  // zIndex: "1000000000000",
                }}
              />
              <span>
                {Math.floor((props.content?.duration / 1000 / 60) << 0)}:
                {Math.floor((props.content?.duration / 1000) % 60)}
              </span>
            </>
            {/* )} */}
          </div>
        </div>
        <div className="list-card-view-tab">
          <MoreVertIcon
            sx={{ fontSize: "25px", color: "var(--primary-color)" }}
            onClick={handleClick}
            aria-controls={open ? "content-menu" : undefined}
            aria-haspopup={"true"}
            aria-expanded={open ? "true" : undefined}
            id="content-menu-btn"
          />
          <Menu
            sx={{ zIndex: "10000000" }}
            id={"content-menu"}
            anchorEl={anchorEl}
            open={open}
            MenuListProps={{
              "aria-labelledby": "content-menu-btn",
            }}
            onClose={handleClose}
            keepMounted
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                props.deleteContent(
                  props.playID,
                  props.content._id
                  // play?.activescreen?._id
                );
              }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DeleteOutlineOutlined sx={{ color: "var(--primary-color)" }} />
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                props.addSchedule(props.playID, props.content._id);
              }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ScheduleSendIcon
                style={{
                  zIndex: "100000000",
                  color: "var(--primary-color)",
                }}
              />
            </MenuItem>
            {/* {props.content.type == "image" && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  onOpenImagesResolutionProps(props.playID, props.content._id);
                }}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ConstructionOutlined />
              </MenuItem>
            )} */}
          </Menu>
        </div>
      </div>

      <Dialog
        open={imageResolutionOpen}
        onClose={onClose}
        maxWidth={"lg"}
        sx={{
          zIndex: "1000000000",
        }}
      >
        <DialogTitle>Image Resolution Properties</DialogTitle>
        <DialogContent>
          <Box sx={{ minHeight: "40vh", width: "40vw" }}>
            <div style={{ margin: "20px 0" }}>
              <Typography variant={"h6"} textAlign={"center"}>
                Image X,Y
              </Typography>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    height: "10%",
                    width: "100%",
                    display: "flex",
                    gap: "5px",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <input
                    type={"radio"}
                    id={"shrink"}
                    name={"radioVal"}
                    checked={form.shrink}
                    onChange={onGetRadio}
                    style={{ height: "20px", width: "20px" }}
                  />{" "}
                  Shrink
                  <input
                    type={"radio"}
                    id={"stretch"}
                    name={"radioVal"}
                    checked={form.stretch}
                    onChange={onGetRadio}
                    style={{ height: "20px", width: "20px" }}
                  />{" "}
                  Stretch
                </div>

                <TextField
                  required
                  variant="standard"
                  name={"x"}
                  value={form.x}
                  onChange={(e) =>
                    setForm((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }))
                  }
                  type={"number"}
                  label="X Cordinates"
                  fullWidth
                  sx={{
                    label: {
                      color: "blue",
                      fontSize: "14px",
                      color: "#92959E",
                    },
                  }}
                />
                <TextField
                  required
                  variant="standard"
                  name={"y"}
                  value={form.y}
                  onChange={(e) =>
                    setForm((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }))
                  }
                  type={"number"}
                  label="Y Cordinates"
                  fullWidth
                  sx={{
                    label: {
                      color: "blue",
                      fontSize: "14px",
                      color: "#92959E",
                    },
                  }}
                />

                <button
                  style={{ marginTop: "40px" }}
                  className="btn-save-content"
                  onClick={onUpdateImageResolution}
                >
                  Save
                </button>
              </div>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default PlaylistContentListItem;
