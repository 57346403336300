import { Box, Dialog, DialogContent, DialogTitle, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'

function Rss({open,onClose,onSubmit}) {
    const [rssData,setRssData]=useState({
        type:'rss',
        title:'',
        link:''
      })
      const _onClose=()=>{
        onClose();
        setRssData({
            type:'rss',
            title:'',
            link:'',
           
          })
      }
      const _onSubmit=(obj)=>{
        onSubmit(obj);
        setRssData({
            type:'rss',
            title:'',
            link:'',
           
          })
      }
  return (
    <>
      <Dialog
      open={open}
      onClose={_onClose}
      maxWidth={'lg'}

      >
<DialogTitle>RSS Feeds</DialogTitle>
<DialogContent>
    <Box sx={{minHeight:'40vh',width:'40vw'}}>
    <div style={{ margin: "20px 0" }}>
              <Typography variant={'h6'} textAlign={'center'}>Rss Widget Form</Typography>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                required
                  variant="standard"
                  name={'title'}
                  value={rssData.title}
                  onChange={(e) => setRssData((prev)=>({...prev,[e.target.name]:e.target.value}))}
                  label="Title"
                  fullWidth
                  sx={{
                    label: {
                      color: "blue",
                      fontSize: "14px",
                      color: "#92959E",
                    },
                  }}
                />
                <TextField
                required
                  variant="standard"
                  name={'link'}
                  value={rssData.link}
                  onChange={(e) => setRssData((prev)=>({...prev,[e.target.name]:e.target.value}))}
                  label="Url Link"
                  fullWidth
                  sx={{
                    label: {
                      color: "blue",
                      fontSize: "14px",
                      color: "#92959E",
                    },
                  }}
                />
              
                
                <button
                  style={{ marginTop: "40px" }}
                  className="btn-save-content"
                  onClick={()=>_onSubmit(rssData)}
                >
                  Save
                </button>
              </div>
            </div>
    </Box>
</DialogContent>
      </Dialog>
    </>
  )
}

export default Rss
