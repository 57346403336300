import React, { useState } from "react";
import TextFormatIcon from "@mui/icons-material/TextFormat";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatSizeIcon from "@mui/icons-material/FormatSize";
import TitleIcon from "@mui/icons-material/Title";
import { Checkbox, Popover, Radio } from "@mui/material";
import { SketchPicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import { setTextFontColor } from "../../../Redux/Actions/EditorAction";
function TextTools({ textProps }) {
  const { _textProps } = useSelector((state) => state.Editor);
  const dispatch = useDispatch();
  const [colorAncherEl, setColorAncherEl] = useState(null);
  const [alignAncherEl, setAlignAncherEl] = useState(null);
  const [fontSizeAncherEl, setFontSizeAncherEl] = useState(null);
  const [fontTransparencyAncherEl, setFontTransparencyAncherEl] =
    useState(null);
  const [canvaColor, setCanvaColor] = useState("black");
  const colorOpen = Boolean(colorAncherEl);
  const alignOpen = Boolean(alignAncherEl);
  const fontSizeOpen = Boolean(fontSizeAncherEl);
  const fontTransparencyOpen = Boolean(fontTransparencyAncherEl);
  const colorId = colorOpen ? "simple-popover" : undefined;
  const alignId = alignOpen ? "simple-popover" : undefined;
  const fontSizeId = fontSizeOpen ? "simple-popover" : undefined;
  const fontTransparencyId = fontTransparencyOpen
    ? "simple-popover"
    : undefined;
  const handleColorClick = (event) => {
    setColorAncherEl(event.currentTarget);
  };
  const handleColorClose = () => {
    setColorAncherEl(null);
  };
  const handleAlignClick = (event) => {
    setAlignAncherEl(event.currentTarget);
  };
  const handleAlignClose = () => {
    setAlignAncherEl(null);
  };
  const handleFontSizeClick = (event) => {
    setFontSizeAncherEl(event.currentTarget);
  };
  const handleFontSizeClose = () => {
    setFontSizeAncherEl(null);
  };
  const handleFontTransparencyClick = (event) => {
    setFontTransparencyAncherEl(event.currentTarget);
  };
  const handleFontTransparencyClose = () => {
    setFontTransparencyAncherEl(null);
  };
  const onChangeColorComplete = (color) => {
    console.log(color);
    // setCanvaColor(color.hex);
    dispatch(setTextFontColor(color.rgb));
    textProps.onChangeTextFontColor(color.rgb);
    // setTimeout(()=>{
    // },800);
  };
  const customAlignIcon = {
    FormatAlignCenterIconChecked: (
      <FormatAlignCenterIcon
        style={{ background: "blue", color: "white" }}
        fontSize="large"
      />
    ),
    FormatAlignLeftIconChecked: (
      <FormatAlignLeftIcon
        style={{ background: "blue", color: "white" }}
        fontSize="large"
      />
    ),
    FormatAlignRightIconChecked: (
      <FormatAlignRightIcon
        style={{ background: "blue", color: "white" }}
        fontSize="large"
      />
    ),
  };
  return (
    <div className="text-tool-container">
      <div className="text-tool">
        <button className="text-tool-btn-top" onClick={handleColorClick}>
          <TextFormatIcon
            style={{
              color: `rgba(${_textProps.fontColor.r},${_textProps.fontColor.g},${_textProps.fontColor.b},${_textProps.fontColor.a})`,
            }}
            fontSize="large"
          />
        </button>
        <Popover
          id={colorId}
          open={colorOpen}
          anchorEl={colorAncherEl}
          onClose={handleColorClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <SketchPicker
            color={canvaColor}
            onChangeComplete={onChangeColorComplete}
          />
        </Popover>
      </div>

      <div className="text-tool">
        <button className="text-tool-btn-top" onClick={handleFontSizeClick}>
          <FormatSizeIcon style={{ color: "black" }} fontSize="large" />
        </button>
        <Popover
          id={fontSizeId}
          open={fontSizeOpen}
          anchorEl={fontSizeAncherEl}
          onClose={handleFontSizeClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div className="text-align-tools">
            <input
              type={"range"}
              min={1}
              onChange={(e) => textProps.onChangeTextFontSize(e.target.value)}
            />
          </div>
        </Popover>
      </div>

      <div className="text-tool">
        <button className="text-tool-btn-top" onClick={handleAlignClick}>
          <FormatAlignCenterIcon style={{ color: "black" }} fontSize="large" />
        </button>
        <Popover
          id={alignId}
          open={alignOpen}
          anchorEl={alignAncherEl}
          onClose={handleAlignClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div className="text-align-tools">
            <Radio
              name={"align"}
              checkedIcon={customAlignIcon["FormatAlignLeftIconChecked"]}
              icon={<FormatAlignLeftIcon fontSize="large" />}
            />
            <Radio
              name={"align"}
              checkedIcon={customAlignIcon["FormatAlignCenterIconChecked"]}
              icon={<FormatAlignCenterIcon fontSize="large" />}
            />
            <Radio
              name={"align"}
              checkedIcon={customAlignIcon["FormatAlignRightIconChecked"]}
              icon={<FormatAlignRightIcon fontSize="large" />}
            />
            {/* <Checkbox checkedIcon={customAlignIcon["FormatAlignCenterIconChecked"]} icon={<FormatAlignLeftIcon fontSize='large' />}/>
             <Checkbox checkedIcon={customAlignIcon["FormatAlignLeftIconChecked"]} icon={<FormatAlignCenterIcon fontSize='large'/>}/>
             <Checkbox checkedIcon={customAlignIcon["FormatAlignRightIconChecked"]} icon={<FormatAlignRightIcon fontSize='large'/>} /> */}
          </div>
        </Popover>
      </div>
      <div className="text-tool">
        <button
          className="text-tool-btn-top"
          onClick={handleFontTransparencyClick}
        >
          <TitleIcon style={{ color: "black" }} fontSize="large" />
        </button>
        <Popover
          id={fontTransparencyId}
          open={fontTransparencyOpen}
          anchorEl={fontTransparencyAncherEl}
          onClose={handleFontTransparencyClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div className="text-align-tools">
            <input
              type={"range"}
              min={1}
              max={10}
              onChange={(e) =>
                textProps.onChangeTextFontTransparency(e.target.value / 10)
              }
            />
          </div>
        </Popover>
      </div>
    </div>
  );
}

export default TextTools;
