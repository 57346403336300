import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TablePagination,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Box,
    Grid,
  } from "@mui/material";
import { DeleteOutlineOutlined} from '@mui/icons-material';
import DesktopMacIcon from "@mui/icons-material/DesktopMac";
function ScreenTableComponent({index,screen,deleteScreenHandler}) {
    const navigate = useNavigate();
  return (
   <>
     <TableRow key={index}>
                            <TableCell align="center">{++index}</TableCell>
                            <TableCell align="center">{screen?.code}</TableCell>
                            <TableCell align="center">{screen?.name}</TableCell>
                            <TableCell align="center">{screen?.mac}</TableCell>
                            <TableCell align="center">
                              {screen?.status ? (
                                <>
                                  <div className="status-badge success"></div>
                                </>
                              ) : (
                                <>
                                  <div className="status-badge danger"></div>
                                </>
                              )}
                            </TableCell>
                            <TableCell align="center" sx={{display:'flex',justifyContent:'center',alignItems:'center',gap:'10px'}}>
                              <DeleteOutlineOutlined
                              
                                style={{ cursor: "pointer",marginTop:'-2px' }}
                                className="main-color"
                                onClick={() =>
                                  deleteScreenHandler(screen._id, screen.mac)
                                }
                              />
                            <DesktopMacIcon
                            className={'main-color'}
                                height={20}
                                width={20}
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate(`/setting/${screen._id}`)}
                            />
                              
                            </TableCell>
                          </TableRow>
   </>
  )
}

export default ScreenTableComponent