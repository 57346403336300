import { useCallback, useEffect, useState } from "react"

const useFilter=(value,data)=>{
  const filtered=useCallback(()=>{
    if(!value || value==''){
      return data;
    }
    let filter=data.filter((_,_index)=>{ 
      return Object.values(_)
      .join('')
      .toLowerCase()
      .includes(value.toLowerCase())
    })
    return filter;
  },[value,data])
  return filtered;
    // const [filtered,setFiltered]=useState([]);
    // useEffect(()=>{
    //   if(!value || value==''){
    //       setFiltered(data);
    //       return;
    //   }
    //   let searched=data.filter(f=>{
    //     return Object.values(f)
    //     .join('')
    //     .toLowerCase()
    //     .includes(value.toLowerCase())
    //   })
    //   setFiltered(searched);
    // },[value,data])
    // return filtered
}
export default useFilter;