import logo from "./logo.svg";
import "./App.css";
import toast, { Toaster } from "react-hot-toast";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getContent } from "./Redux/Actions/contentAction";
import {
  getUsers,
  login,
  logout,
  refreshLogin,
} from "./Redux/Actions/userAction";
import Content from "./views/content/Content";
import Login from "./views/login/Login";
import User from "./views/user/User";
import {
  getScreens,
  getScreensBySuperadmin,
  screenStatusActive,
  screenStatusDeActive,
} from "./Redux/Actions/screenAction";
import Screen from "./views/screen/Screen";
import Playlist from "./views/playlist/Playlist";
import {
  getPlaylist,
  getPlaylistByPredecessor,
} from "./Redux/Actions/playlistAction";
import ForgetPassword from "./views/login/ForgetPassword";
import Dashboard from "./views/dashboard/Dashboard";
import Setting from "./views/setting/Setting";
import { socketInit } from "./socket";
import PrivacyPolicy from "./views/privacypolicy/PrivacyPolicy";
import ChangePassword from "./views/login/ChangePassword";
import { useRefreshLogin } from "./hooks/useRefreshLogin";
import { Box } from "@mui/material";
import loginBackgroundIMG from "./Assets/images/Meliora-Background.jpg";
// import Sharing from './views/sharing/Sharing';
import UserPanel from "./views/user/UserPanel";
import DesignEditor from "./designeditor/Index";
import axios from "axios";
import { pdfjs } from "react-pdf";
import Loader from "./components/loader/Loader";
import dataCity from "./api/cities500.json";
import Company from "./views/company/Company";
import { _GetFoldersByUser, GetFolders } from "./Redux/Actions/folderAction";
import Partner from "./views/partner/Partner";
import {
  getPartners,
  getPartnersBySuperadmin,
} from "./Redux/Actions/partnerAction";
import {
  getCompaniesBySuperadmin,
  getCompany,
} from "./Redux/Actions/companyAction";
import Packet from "./views/packet/Packet";
import { getPackets } from "./Redux/Actions/packetAction";
// import WebPlayer from './views/webplayer/WebPlayer';
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();
const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

function App() {
  const dispatch = useDispatch();
  const { user, auth } = useSelector((state) => state.User);
  const { loading } = useRefreshLogin();

  let timer;
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      logoutAction();
    }, 5 * 60 * 1000);
  };
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };
  useEffect(() => {
    const fetchWeather = async (obj) => {
      const request = await axios.get(
        `${obj.hostName}/data/2.5/weather?q=${obj.city}&units=${obj.unit}&APPID=${obj.appId}`
      );
      const response = await request.data;
      // console.log(response)
      dataCity.forEach((e, index) => index <= 10 && console.log(e));
    };
    // fetchWeather({city:'Lahore',unit:'metric',appId:'637cd51f54778ca88339846c4d0caaa7',hostName:'https://api.openweathermap.org'});
  }, []);
  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);
  const logoutAction = () => {
    // resetTimer();
    socketRef.current.emit("leaving--connection");
    dispatch(logout());
    // window.location.pathname = "/login";
  };

  const socketRef = useRef();
  useEffect(() => {
    const connection = () => {
      socketRef.current = socketInit();
      // console.log(socketRef.current)
      // socketRef.current.emit("join", {
      //   detail: {
      //     mac: "49359fee60e59d43",
      //     device_details: {
      //       ip: "192.168.232.2",
      //       height: 1600,
      //       width: 2560,
      //       os_version: "7.1.1",
      //       manufacture: "Google",
      //       device_name: "Google Android SDK built for x86",
      //       software: "1.0",
      //       root_level: true,
      //     },
      //     ram: { total: "3038 MB", free: "2507 MB" },
      //     storage: { total: "2006 MB", free: "1771 MB" },
      //   },
      // });

      socketRef.current.on("screen", (data) => {
        console.log(data);
      });
      socketRef.current.on("message_recieved", ({ message }) => {
        if (message) {
          toast.success("message successfully");
        }
      });
      socketRef.current.on("admin_screen", ({ detail, left }) => {
        // console.log(detail);
        dispatch(screenStatusActive(detail.mac, true));
      });
      socketRef.current.on("leave", (obj) => {
        // console.log(obj);
        dispatch(screenStatusDeActive(obj.mac, false));
      });
      // socketRef.current.on('screen--sharing--success',({msg,screenShort})=>{
      //   console.log(msg,screenShort)
      // })
    };
    connection();
  }, []);
  useEffect(() => {
    if (auth) {
      dispatch(getPackets());
      if (user.role == "superadmin") {
        dispatch(getPartnersBySuperadmin());
        dispatch(getCompaniesBySuperadmin());
        dispatch(getScreensBySuperadmin());
      } else {
        if (user.role == "user-admin") {
          dispatch(getContent(user?.parentId?._id));
          dispatch(getScreens(user?.parentId?._id));
          dispatch(getUsers(user?.parentId?._id));
          dispatch(getPlaylist(user?.parentId?._id));
          dispatch(GetFolders(user?.parentId?._id));
          dispatch(getPartners(user?.parentId?._id));
          dispatch(getCompany(user?.parentId?._id));
        } else {
          if (user.role == "company") {
            dispatch(getPlaylist(user?._id));
          } else {
            dispatch(getPlaylistByPredecessor(user?._id));
          }
          dispatch(getContent(user?._id));
          dispatch(getScreens(user?._id));
          dispatch(getUsers(user?._id));
          // dispatch(getPlaylistByPredecessor(user?._id));
          if (user?.role == "user") {
            dispatch(_GetFoldersByUser(user?._id));
          } else {
            dispatch(GetFolders(user?._id));
          }
          dispatch(getPartners(user?._id));
          dispatch(getCompany(user?._id));
        }
      }
    }
    // dispatch(getPlaylist());
  }, [auth, user]);
  return loading ? (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          maxWidth: "80vw",
          backgroundImage: `url(/images/meliora-logo.png)`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "contain",
          position: "relative",
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </Box>
      </Box>
    </>
  ) : (
    <div className="App">
      <Toaster
        position={"top-center"}
        toastOptions={{
          duration: 3000,
        }}
      />
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path={"/changepassword/:id/:token"}
            element={<ChangePassword />}
          />
          <Route exact path={"/forgetpassword"} element={<ForgetPassword />} />
          <Route exact path={"/privacypolicy"} element={<PrivacyPolicy />} />
          <Route exact path={"/login"} element={<Login />} />
          <Route exact path={"/design-editor"} element={<DesignEditor />} />
          <Route element={<PrivateRouter />}>
            <Route
              exact
              path={"/"}
              element={<Dashboard user={user} socketRef={socketRef} />}
            />
            <Route
              exact
              path={"/content"}
              element={<Content user={user} socketRef={socketRef} />}
            />
            <Route
              exact
              path={"/content/:_id"}
              element={<Content user={user} socketRef={socketRef} />}
            />
            <Route
              exact
              path={"/screen"}
              element={<Screen user={user} socketRef={socketRef} />}
            />
            <Route
              exact
              path={"/screen/:_id"}
              element={<Screen user={user} socketRef={socketRef} />}
            />
            <Route
              exact
              path={"/playlist"}
              element={
                <Playlist user={user} auth={auth} socketRef={socketRef} />
              }
            />

            {/* <Route
              exact
              path={"/playlist/:_id"}
              element={
                <Playlist user={user} auth={auth} socketRef={socketRef} />
              }
            /> */}

            <Route
              exact
              path={"/user-panel/:id"}
              element={<UserPanel user={user} socketRef={socketRef} />}
            />
            <Route
              exact
              path={"/setting/:id"}
              element={<Setting user={user} socketRef={socketRef} />}
            />
            <Route element={<PartnerCompanyUserRouter />}>
              <Route
                exact
                path={"/user"}
                element={<User user={user} socketRef={socketRef} />}
              />
            </Route>
            <Route element={<SuperAdminRouter />}>
              <Route
                exact
                path={"/screen"}
                element={<Screen user={user} socketRef={socketRef} />}
              />
              <Route exact path="/partner" element={<Partner user={user} />} />
              <Route
                exact
                path="/packet"
                element={
                  <Packet user={user} auth={auth} socketRef={socketRef} />
                }
              />
            </Route>
            <Route element={<SuperAdminPartnerRouter />}>
              <Route
                exact
                path={"/company"}
                element={<Company user={user} socketRef={socketRef} />}
              />
            </Route>
            {/* <Route
          exact 
          path='/web-player'
          element={<WebPlayer socketRef={socketRef}/>}
          /> */}
            {/* <Route
            exact
            path={"/sharing/:id"}
            element={<Sharing user={user} socketRef={socketRef} />}
          /> */}
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
function PrivateRouter() {
  const { auth } = useSelector((state) => state.User);
  return <>{auth ? <Outlet /> : <Navigate to={"/login"} />}</>;
}
function SuperAdminPartnerRouter() {
  const { user, auth } = useSelector((state) => state.User);
  return (
    <>
      {auth && (user.role == "superadmin" || user.role == "partner") ? (
        <Outlet />
      ) : (
        <Navigate to={"/"} />
      )}
    </>
  );
}
function SuperAdminRouter() {
  const { user, auth } = useSelector((state) => state.User);
  return (
    <>
      {auth && user.role == "superadmin" ? <Outlet /> : <Navigate to={"/"} />}
    </>
  );
}
function PartnerCompanyUserRouter() {
  const { user, auth } = useSelector((state) => state.User);
  return (
    <>
      {auth &&
      (user.role == "company" ||
        user.role == "partner" ||
        user.role == "user-admin") ? (
        <Outlet />
      ) : (
        <Navigate to={"/"} />
      )}
    </>
  );
}

export default App;
