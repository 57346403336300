import React from "react";

function FolderWrapper({ children }) {
  return (
    <>
      <div className={"flex flex-wrap justifyStart alignItemCenter margin"} style={{gap:'5px'}}>
        {children}
      </div>
    </>
  );
}

export default FolderWrapper;
