import { Dialog, DialogContent, DialogTitle, Select, MenuItem } from '@mui/material';
import React from 'react'
import MiniLoader from '../loader/MiniLoader';
import { useDispatch } from 'react-redux';

function MoveFolder({isMoving,isOpen,onClose,_folders,folderId,onGetFolderId,moveMethod,}) {
  const dispatch=useDispatch();
    return (
   <>
     <Dialog
        open={isOpen}
        onClose={onClose}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle>Move to folder</DialogTitle>
        <DialogContent>
          <div style={{ margin: "20px 0" }}>
            <div
              style={{
                display: "flex",
                gap: "10px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Select
                onChange={(e) => onGetFolderId(e.target.value)}
                variant={"outlined"}
                label="Select folder"
                fullWidth
              >
                <MenuItem>--Select folder--</MenuItem>
                <MenuItem value="Home">Home</MenuItem>
                {_folders.map((_, index) => (
                  <MenuItem key={index} value={_._id}>
                    {_.name}
                  </MenuItem>
                ))}
              </Select>

              <button
                style={{ marginTop: "40px" }}
                className="btn-save-content"
                onClick={() => {
                  dispatch(moveMethod);
                  // setIsOpenFolderModel(false);
                }}
              >
                {
                  isMoving ? <MiniLoader/> : <> Move</>
                }
               
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
   </>
  )
}

export default MoveFolder
