import { fabric } from "fabric";
import {v4 as uuid} from "uuid";
export default class EditorOps {
  createRect() {
    return new fabric.Rect({
      id: uuid(),
      top: 50,
      left: 50,
      name:'Shape',
      cornerColor: "black",
      // borderColor:'red',
      // backgroundColor:'green',
      width: 100,
      height: 100,
      fill: "gray",
    });
  }
  createCircle() {
    return new fabric.Circle({
      id: uuid(),
      name:'Shape',
      top: 50,
      left: 50,
      cornerColor: "black",
      borderColor: "red",
      width: 300,
      height: 300,
      radius: 50,
      // stroke: "black",
      // strokeWidth: 2,
      fill: "gray",
    });
  }
  createLine() {
    return new fabric.Line([50, 50, 250, 50], {
      id: uuid(),
      name:'Shape',
      stroke: "black",
      strokeWidth: 2,
      // strokeDashArray: [1, 1]
    });
  }
  createPolygone() {
    return new fabric.Polygon(
      [
        {
          x: 150,
          y: 50,
        },
        {
          x: 225,
          y: 150,
        },
        {
          x: 150,
          y: 250,
        },
        {
          x: 75,
          y: 150,
        },
      ],
      {
        id: uuid(),
        name:'Shape',
        fill: "transparent",
        stroke: "black",
        strokeWidth: 2,
      }
    );
  }
  createTriangle() {
    return new fabric.Triangle({
      id: uuid(),
      name:'Shape',
      width: 100,
      height: 100,
      stroke: "black",
      strokeWidth: 2,
      fill: "transparent",
      left: 50,
      top: 50,
    });
  }
  addEmojiToCanvas(emoji) {
    return new fabric.Text(emoji, {
      id: uuid(),
      name:'Text',
      top: 50,
      left: 50,
      editable: true,
      cornerColor: "black",
      borderColor: "red",
      textAlign: "center",
    });
  }
  addTextShadow(){
   return new fabric.Shadow({
      color: 'black',
      blur: 5
  });
  }
  addTextbox(){
    return new fabric.Textbox("Enter here", {
      id:uuid(),
      name:'Textbox',
      top: 50,
      left: 50,
      editable: true,
      cornerColor: "black",
      borderColor: "black",
      textAlign: "center"
  });
  }
}
