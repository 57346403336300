import React from 'react'
import icon from "../../../../../Assets/icons/cloud.png";
import sunIcon from '../../../../../Assets/icons/sun.png';
import MiniCard from './MiniCard';
import MainCard from './MainCard';
function WeatherGlassmorph({weather}) {
  return (
   <>
    <div
            style={{
              height: "100%",
              width: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
           <MainCard
           icon={icon}
           weather={weather}
           />
          </div>
          <div
            style={{
              height: "100%",
              width: "50%",
              // border: "2px solid black",
              display: "flex",
              gap: "5px",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                height: "50%",
                width: "100%",
                // border: "1px solid white",
                display: "flex",
                justifyContent:'center',
                alignItems:'center',
                gap: "3px",
                flexWrap: "wrap",
              }}
            >
              {
                weather?.list?.map((ele,index)=>{
                  const date = new Date(ele.dt_txt);
                  const day = date.toLocaleDateString("en-US", {
                    weekday: "short",
                  });
                  return (
                    <MiniCard
                    key={index}
                    icon={sunIcon}
                    data={{...ele,day}}
                    />
                  )
                }
                )
              }
            {/* <MiniCard
            icon={sunIcon}
            />
            <MiniCard
            icon={sunIcon}
            /> */}

            </div>
          </div>
   </>
  )
}

export default WeatherGlassmorph
