import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";

function AppWidgetForm({
  isOpen,
  onClose,
  isFullWidth,
  maxWidth,
  children,
  title,
  onOpenWidgetForm,
}) {
  return (<>
  <Dialog 
  maxWidth={maxWidth}
  fullWidth={isFullWidth}
   open={isOpen}
   onClose={onClose}
  >
  <DialogTitle>{title}</DialogTitle>
  <DialogContent>
    {children}
  </DialogContent>
  </Dialog>
  </>);
}

export default AppWidgetForm;
