import { DialogTitle, Button } from "@mui/material";
import React from "react";

function PreviewEmbed({url,onClose,onConfirm}) {
  return (
    <>
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "3px solid var(--primary-color)",
          fontSize: "18px",
          fontWeight: 700,
        }}
      >
        Embed Preview
      </DialogTitle>
      <div
        style={{
          height: "10dvh",
          width: "30dvw",
          display: "flex",
          justifyContent: "center",
          // border:'1px solid black'
        }}
      >
        <div
          style={{
            height: "100px",
            width: "100px",
          }}
        >
          <img
            src="/images/embedIcon.png"
            style={{ height: "100%", width: "100%" }}
          />
        </div>
      </div>

      <div
        style={{
          maxHeight: "30dvh",
          width: "30dvw",
          overflow:'auto',
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 10,
          gap: 10,
        }}
        dangerouslySetInnerHTML={{ __html: url }}
      >
       
      </div>
      <div
        style={{
          maxHeight: "10dvh",
          width: "30dvw",
          display: "flex",
          justifyContent: "center",
          padding: 10,
          gap: 10,
        }}
      >
        <Button onClick={onConfirm} variant={"contained"} style={{background:'var(--primary-color)'}} title="Preview">
          Confirm
        </Button>
        <Button onClick={onClose} variant={"contained"} style={{background:'var(--primary-color)'}} title="Submit">
          Cancel
        </Button>
      </div>
    </>
  );
}

export default PreviewEmbed;
