import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./design.css";
const Design = ({ onGetImage, layer }) => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const openDirectory = () => {
    const dir = document.querySelector("#file-get");
    dir.click();
  };
  // console.log(layer)
  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                label="Layers"
                value="1"
                sx={{ textTransform: "capitalize" }}
              />
              <Tab
                label="Templete"
                value="2"
                sx={{ textTransform: "capitalize" }}
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <div className="layer-container">
              {layer.map((l, index) => {
                return (
                  <>
                    <div className="layers">{<img src={l.toDataURL()} />}</div>
                  </>
                );
              })}
            </div>
          </TabPanel>
          <TabPanel value="2">
            <input
              id={"file-get"}
              type={"file"}
              onChange={(e) => onGetImage(e.target.files[0])}
              hidden
            />
            <button onClick={openDirectory}>Select File</button>
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};
export default Design;
