import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import PrimarySearchBar from "../../components/searchBar/searchbar";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Input,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  DeleteOutlineOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import Loader from "../../components/loader/Loader";
import toast from "react-hot-toast";
import "../user/user.css";
import {
  deleteUser,
  getUsers,
  login,
  userFlagOff,
  userRegister,
} from "../../Redux/Actions/userAction";
import DeleteModel from "../../components/deletemodel/DeleteModel";
import useFilter from "../../hooks/useFilter";
import { deletePartner, partnerRegister, resetPartner } from "../../Redux/Actions/partnerAction";

function Partner({ user }) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
  });
  const dispatch = useDispatch();
  const {
    isUserLoading,
    users,
    isUserAdded,
    isAlreadyAvailable,
    isPartnerDelete,
    partners,
    isPartnerLoading
  } = useSelector(
    (state) => ({ 
        isUserLoading: state.User.isUserLoading,
        users:state.User.users,
        isUserAdded:state.User.isUserAdded,
        isAlreadyAvailable:state.User.isAlreadyAvailable,
        isPartnerDelete:state.Partner.isPartnerDelete,
        isPartnerLoading: state.Partner.isPartnerLoading,
        partners:state.Partner.partners
    }),
    shallowEqual
  );

  const [openForm, setOpenForm] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [userId, setUserId] = useState("");
  const [textVal, setTextVal] = useState("");
  const [filteredUser, setFilteredUser] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [role, setRole] = useState("partner");
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const filtered = useFilter(textVal, partners)();
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // console.log('logedin=>',user)
  const submitUser = (data) => {
    const { companyName, email, username, password } = data;
    dispatch(
      userRegister({
        companyName,
        email,
        username,
        password,
        role: role,
        user: user._id,
      })
    );
    setOpenForm(false);
    reset({
      companyName: "",
      email: "",
      username: "",
      password: "",
    });
  };
//   useEffect(() => {
//     dispatch(getUsers(user?._id));
//   }, []);
  useEffect(() => {
    if (isUserAdded) {
      toast.success("Company registered successfully");
      setFilteredUser([...users]);
      dispatch(userFlagOff());
    }
    if (isAlreadyAvailable) {
      toast.error("Already registered.");
      dispatch(userFlagOff());
    }
    if (isPartnerDelete) {
      toast.success("Partner deleted");
      // setFilteredUser([...users]);
      dispatch(resetPartner());
    }
  }, [isUserAdded, isAlreadyAvailable, isPartnerDelete]);
  const askingForDelete = (id) => {
    setUserId(id);
    setOpenDeleteModel(true);
  };
  const confirmDelete = () => {
    dispatch(deletePartner(userId));
    setOpenDeleteModel(false);
  };
  const searchHandler = (e) => {
    setTextVal(e);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const submitLogin = (email,password) => {
    if (email === "") {
      return;
    }
    if (password === "") {
      return;
    }
    dispatch(login(email, password));
    return navigate("/");
  };
  return (
    <>
      <Layout title={"Partner"} user={user}>
        <div className="parent-container">
          <div className="child-container">
            <Grid container>
              <Box className={"control-area"}>
                <Grid lg={8}>
                  <Box
                    sx={{
                      background: "white",
                      padding: "33px 0",
                      marginRight: "20px !important",
                      borderRadius: "16px",
                    }}
                  >
                    <PrimarySearchBar
                      value={textVal}
                      onChange={searchHandler}
                    />
                  </Box>
                </Grid>
                <Grid lg={4} display={"flex"} justifyContent={"flex-end"}>
                  <div className="user-add-btn">
                    <button onClick={() => setOpenForm(true)}>
                      +Add Partner
                    </button>
                  </div>
                </Grid>
              </Box>
            </Grid>
          </div>

          <div className="table">
            <TableContainer
              component={Paper}
              sx={{
                borderRadius: "16px",
              }}
            >
              <Table className="table-background">
                <TableHead>
                  <TableRow
                    style={{ borderBottom: "2px solid var(--primary-color)" }}
                  >
                    <TableCell align="center">ID</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">Partner</TableCell>
                    <TableCell align="center">Username</TableCell>
                    <TableCell align="center">Email</TableCell>
                    <TableCell align="center">Role</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isUserLoading ? (
                    <>
                      <Loader />
                    </>
                  ) : (
                    filtered.length >= 0 &&
                    filtered
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <>
                          <TableRow>
                            <TableCell align="center">{++index}</TableCell>
                            <TableCell align="center">
                              {new Date(row.createdAt).toLocaleString()}
                            </TableCell>
                            <TableCell align="center">
                              {row.companyName}
                            </TableCell>
                            <TableCell align="center">{row.username}</TableCell>
                            <TableCell align="center">{row.email}</TableCell>
                            <TableCell align="center">{row.role}</TableCell>
                            <TableCell align="center">
                              <Visibility
                               onClick={()=>submitLogin(row.email,row.password)}
                                style={{
                                  cursor: "pointer",
                                  margin: "-3px 5px",
                                  color: "var(--primary-color)",
                                }}
                              />
                              <DeleteOutlineOutlined
                                fontSize={"medium"}
                                onClick={() => askingForDelete(row._id)}
                                sx={{
                                  color: "var(--primary-color)",
                                }}
                              ></DeleteOutlineOutlined>
                            </TableCell>
                          </TableRow>
                        </>
                      ))
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                style={{ display: "flex", justifyContent: "space-between" }}
                count={filtered.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              ></TablePagination>
            </TableContainer>
          </div>
        </div>

        <DeleteModel
          open={openDeleteModel}
          onClose={(e) => setOpenDeleteModel(e)}
          onConfirm={confirmDelete}
        />

        <Dialog
          // maxWidth={'xl'}
          open={openForm}
          onClose={() => setOpenForm(false)}
          sx={{
            "& .MuiPaper-root-MuiDialog-paper": {
              borderRadius: "16px",
            },
          }}
          className="dialogBorder"
        >
          <DialogTitle
            style={{
              textAlign: "center",
              borderBottom: "2px solid var(--primary-color)",
              fontSize: "18px",
              fontWeight: 700,
            }}
          >
            Register Company
          </DialogTitle>
          <DialogContent
            sx={{
              pb: 5,
            }}
          >
            <form onSubmit={handleSubmit(submitUser)}>
              <div className="user-add-form-container">
                <div className="form-fields">
                  <TextField
                    {...register("companyName", {
                      required: true,
                    })}
                    type={"text"}
                    variant={"standard"}
                    label={"Partner Name*"}
                    fullWidth
                  />
                  <Typography>
                    {errors.companyName && "company name is required"}
                  </Typography>
                </div>
                <div className="form-fields">
                  <TextField
                    {...register("username", {
                      required: true,
                    })}
                    type={"text"}
                    variant={"standard"}
                    label={"Username*"}
                    fullWidth
                  />
                  <Typography>
                    {errors.username && "username is required"}
                  </Typography>
                </div>
                <div className="form-fields">
                  <TextField
                    {...register("email", {
                      required: true,
                    })}
                    type={"email"}
                    variant={"standard"}
                    label={"forexample@gmail.com"}
                    fullWidth
                  />
                  <Typography>{errors.email && "email is required"}</Typography>
                </div>
                <div className="form-fields">
                  <FormControl sx={{ width: "100%" }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-password">
                      Password*
                    </InputLabel>
                    <Input
                      {...register("password", {
                        required: true,
                      })}
                      id="standard-adornment-password"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? (
                              <VisibilityOff
                                sx={{
                                  color: "#551FFF",
                                }}
                              />
                            ) : (
                              <Visibility
                                sx={{
                                  color: "#551FFF",
                                }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <Typography>
                    {errors.password && "password is required"}
                  </Typography>
                </div>

                <div className="form-fields">
                  <FormControl variant={"standard"} fullWidth>
                    <InputLabel id="role-id">Role</InputLabel>
                    <Input
                      labelId={"role-id"}
                      type={"text"}
                      readOnly
                      disabled
                      value={role}
                      //   onChange={(e) => setRole(e.target.value)}
                    />
                  </FormControl>
                  {/* <Select
                  variant={'standard'}
                  labelId="role-id"
                  {
                    ...register(
                      'role',
                      {
                        required:true
                      }
                    )
                  }
                  fullWidth
                >
                  {/* <MenuItem value='admin'>Admin</MenuItem> 
                  <MenuItem value='user'>User</MenuItem>
                </Select> */}
                  {/* <Typography>{errors.role && 'role is required'}</Typography> */}
                </div>

                <div className="add-user-form-submit-btn">
                  <button type={"submit"}>Add</button>
                </div>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      </Layout>
    </>
  );
}

export default Partner;
