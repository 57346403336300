import {
  COMPANY_DELETE_SUCCESS,
  COMPANY_SAVE_FAILED,
  COMPANY_SAVE_SUCCESS,
  GET_COMPANY_FAILED,
  GET_COMPANY_REQUEST,
  GET_COMPANY_SUCCESS,
  RESET_COMPANY,
} from "../constant";

const initial = {
  companies: [],
  isCompanyLoading: false,
  isCompanyAdded: true,
  isCompanyDeleted:false,
  isCompanyAlreadyAvailable: false,
};
export const CompanyReducer = (state = initial, action) => {
  switch (action.type) {
    case RESET_COMPANY:
      return {
        ...state,
        isCompanyLoading: false,
        isCompanyAdded: true,
        isCompanyDeleted:false,
        isCompanyAlreadyAvailable: false,
      }
    case COMPANY_SAVE_SUCCESS:
      return {
        ...state,
        companies: [...state.companies, action.payload].reverse(),
        isCompanyAdded: true,
        isCompanyAlreadyAvailable: false,
      };
    case COMPANY_SAVE_FAILED:
      return {
        ...state,
        isCompanyAdded: false,
        isCompanyAlreadyAvailable: true,
      };
    case GET_COMPANY_REQUEST:
      return {
        ...state,
        isCompanyLoading: true,
      };
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        isCompanyLoading: false,
        companies: [...action.payload].reverse(),
      };
    case GET_COMPANY_FAILED:
      return {
        ...state,
        isCompanyLoading: false,
      };
      case COMPANY_DELETE_SUCCESS:
        return {
          ...state,
          isCompanyDeleted: true,
          companies:state.companies.filter(u => u._id !== action.payload)
        }
    default:
      return state;
  }
};
