import React, { useEffect } from "react";
import "./picture.css";
import { Box } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
export default function Picture({ onGetImage }) {
  useEffect(() => {
    fetch(
      "https://api.unsplash.com/photos?client_id=e411zzD4bAdrWICpDPTai9k3tS2rFIUEtVuAx0GBdYc"
    ).then((res) => console.log(res));
  }, []);
  const openDirectory = () => {
    const dir = document.querySelector("#file-get");
    dir.click();
  };
  return (
    <div className="picture-component-container">
      <input
        id={"file-get"}
        type={"file"}
        onChange={(e) => onGetImage(e.target.files[0])}
        hidden
      />
      <button onClick={openDirectory}>Upload Image</button>
      <div className="upslash-container"></div>
    </div>
  );
}