import {
  SET_BACKGROUND_COLOR_OF_CANVA,
  SET_LENGTH_OF_CANVA,
  SET_TEXT_FONT_COLOR,
} from "../constant";

const setCanvaLength = (obj) => {
  return async (dispatch) => {
    dispatch({ type: SET_LENGTH_OF_CANVA, payload: obj });
  };
};
const setCanvaBGColor = (color) => {
  return async (dispatch) => {
    dispatch({ type: SET_BACKGROUND_COLOR_OF_CANVA, payload: color });
  };
};
export const setTextFontColor=(color)=>{
  return async (dispatch)=>{
    dispatch({
      type:SET_TEXT_FONT_COLOR,
      payload:color
    })
  }
}
export const resetTextFontIsChanged=()=>{
  return async (dispatch)=>{
    dispatch({
      type:"Reset",
      payload:''
    })
  }
}