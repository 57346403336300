import React from 'react'

function Video({onGetVideo}) {
    const openDirectory=()=>{
        const dir=document.querySelector('#file-get');
        dir.click();
    }
  return (
    <div>
      <input id={'file-get'} type={'file'} onChange={(e)=>onGetVideo(e.target.files[0])} hidden/>
       <button onClick={openDirectory}>Upload Video</button>
    </div>
  )
}

export default Video