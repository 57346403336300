import React, { useEffect, useState } from "react";
import "./editor.css";
import { Divider, Popover } from "@mui/material";
import { SketchPicker } from "react-color";
import { useSelector } from "react-redux";
function CanvaProperty({canvaProps,onSetLengthOfCanvas }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [colorAncherEl, setColorAncherEl] = useState(null);
  const [canvaColor, setCanvaColor] = useState("white");
  // const {height,width}=useSelector(state=>state.Editor);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleColorClick = (event) => {
    setColorAncherEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleColorClose = () => {
    setColorAncherEl(null);
  };
  const open = Boolean(anchorEl);
  const colorOpen = Boolean(colorAncherEl);
  const id = open ? "simple-popover" : undefined;
  const colorId = colorOpen ? "simple-popover" : undefined;
  const [length, setLength] = useState({
    height: "",
    width: "",
  });
  useEffect(() => {
    setLength({ height: canvaProps?.height, width: canvaProps?.width });
    if(canvaProps.canvaColor!==undefined) setCanvaColor(canvaProps.canvaColor);
  }, [canvaProps.height, canvaProps.width, canvaProps.canvaColor]);
  const onGetLength = (e) => {
    const { name, value } = e.target;
    setLength((prev) => ({ ...prev, [name]: value }));
  };
  const onChangeColorComplete = (color) => {
    setCanvaColor(color.hex);
    canvaProps.onGetChangeCanvaBackgroundColor(color.hex);
  };
  console.log("from canva props=", canvaColor);
  return (
    <div className="canvas-prop-container">
      <div className="fields-prop">
        <button
          style={{
            background: canvaColor,
            border: "1px solid black",
            height: "39px",
            width: "39px",
          }}
          aria-describedby={colorId}
          onClick={handleColorClick}
        ></button>
        <Popover
          id={colorId}
          open={colorOpen}
          anchorEl={colorAncherEl}
          onClose={handleColorClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <SketchPicker
            color={canvaColor}
            onChangeComplete={onChangeColorComplete}
          />
        </Popover>
      </div>
      <div className="fields-prop">
        <button
          className="canva-size-button"
          aria-describedby={id}
          onClick={handleClick}
        >
          Canva Size
        </button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          //  className="popover_class"
        >
          <div className="pop-over">
            Height:
            <input
              name="height"
              value={length.height}
              onChange={onGetLength}
              type={"number"}
            />
            Width:
            <input
              name="width"
              value={length.width}
              onChange={onGetLength}
              type={"number"}
            />
            <button
              className="canva-confirm-size-button"
              onClick={() => onSetLengthOfCanvas(length)}
            >
              Confirm
            </button>
          </div>
        </Popover>
        {/* Height:<input name="height" value={canvasProps.height} onChange={onGetLength} type={'number'}/> */}
      </div>
      <Divider orientation="vertical" />
      <div className="fields-prop">Clear</div>
    </div>
  );
}

export default CanvaProperty;
