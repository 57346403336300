import React from "react";
import "./shape.css";
import Crop54Icon from "@mui/icons-material/Crop54";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import HorizontalRuleOutlinedIcon from "@mui/icons-material/HorizontalRuleOutlined";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
function Shape({ onDrawShapes,onAddEmojiToCanva }) {
  const handleAddEmoji=(emoji)=>{
     onAddEmojiToCanva(emoji.native);
  }
  return (
    <div className="shape-container">
      <div className="shape-box" onClick={() => onDrawShapes("Rect")}>
        <Crop54Icon fontSize={"large"} />
        {/* <button onClick={()=>onDrawShapes('Rect')}>rect</button> */}
      </div>
      <div className="shape-box" onClick={() => onDrawShapes("Circle")}>
        <RadioButtonUncheckedIcon fontSize={"large"} />
        {/* <button onClick={()=>onDrawShapes('Circle')}>Circle</button> */}
      </div>
      <div className="shape-box" onClick={() => onDrawShapes("Triangle")}>
        <ChangeHistoryIcon fontSize="large" />
      </div>
      <div className="shape-box" onClick={() => onDrawShapes("Star")}>
        <StarBorderIcon fontSize="large" />
      </div>
      <div className="shape-box" onClick={() => onDrawShapes("Line")}>
        <HorizontalRuleOutlinedIcon fontSize="large" />
      </div>
      <Picker
        data={data}
        set="native"
        locale="zh"
        onEmojiSelect={handleAddEmoji}
      />
    </div>
  );
}

Shape.propTypes = {};

export default Shape;
