import React from 'react'

function MiniCard({icon,data}) {
  return (
   <>
     <div
              className="mini-card"
              >
                <div style={{width:'90%',height:'20%',fontSize:'20px',borderBottom:'2px solid white',color:'white',fontWeight:'bold',display:'flex',justifyContent:'center'}}>
                  {data?.day}
                </div>
                <img
                                  src={`https://openweathermap.org/img/wn/${data.weather[0].icon}.png`}
                                  alt="icon"
                                />
                {/* <img src={icon} style={{height:'50%',width:'50%'}}/> */}
                <span style={{color:'white',fontWeight:'bold'}}>{data.main.temp} C</span>
                <h5 style={{color:'white',fontWeight:'bold'}}>{data.weather[0].description}</h5>
              </div>
   </>
  )
}

export default MiniCard
