import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './pdf.css';
import { addContent, deleteContent } from '../../../../Redux/Actions/formAction';
import { Document, Page } from 'react-pdf';
import { imgUrl } from '../../../../api/api';
import { PictureAsPdfOutlined } from '@mui/icons-material';
const PdfTab = ({ pId }) => {
  const { allContent } = useSelector((state) => state.Form);
  const dispatch = useDispatch();
  const [pageNumber,setPageNumber]=useState('');
  const checkBox = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      dispatch(
        addContent(
          pId,
          allContent.find((c) => c.id === value)
        )
      );
    } else {
      dispatch(deleteContent(pId, value));
    }
  };
  return (
    <>
      <div className="image-container">
        {allContent.map(
          (data) =>
            data.type === 'application' && (
              <>
                <div className="image-content-cards" key={data.id}>
                {/* <div style={{margin:'0 10px'}}> */}
                 <input
                    type="checkbox"
                    name={data.name}
                    value={data.id}
                    checked={data.selected}
                    onChange={checkBox}
                    id={`checkbox${data.id}`}
                    style={{height:'15px',width:'15px',display:'none'}}
                    hidden
                  />
                 {/* </div> */}
                  <label htmlFor={`checkbox${data.id}`} className="upper-layer">
                   
                  </label>
                  <div className="image-content-card-image check-tick">
                  <div style={{ height: {xl:"230px",lg:"230px",md:'230px',sm:'150px',xs:'150px'}, width: {xl:"230px",lg:"230px",md:'230px',sm:'150px',xs:'150px'},display:"flex",justifyContent:"center",alignItems:"center" }}>
            <PictureAsPdfOutlined
              sx={{
                height:'60%',
                width:'60%',
  
              }}
            />
            </div> 
                    {/* <Document
              file={`${imgUrl}/${data?.url}`}
              onLoadError={(error) => console.log("failed=", error)}
              onLoadSuccess={({ numPages }) => setPageNumber(numPages)}
            >
              <Page
                pageNumber={pageNumber}
                height={'130'}
                width={'130'}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            </Document> */}
                    </div>
                    <div className="image-content-card-footer">
                      <p>{data?.name?.substring(0,20)}</p>
                    </div>
                </div>
              </>
            )
        )}
      </div>
    </>
  );
};
export default PdfTab;
