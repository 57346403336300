import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './url.css';
import { addContent, deleteContent } from '../../../../Redux/Actions/formAction';
import { LanguageOutlined } from '@mui/icons-material';
const Url = ({ pId }) => {
  const { allContent } = useSelector((state) => state.Form);
  const dispatch = useDispatch();
  const checkBox = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      dispatch(
        addContent(
          pId,
          allContent.find((c) => c.id === value)
        )
      );
    } else {
      dispatch(deleteContent(pId, value));
    }
  };
  return (
    <>
      <div className="image-container">
        {allContent.map(
          (data) =>
            data.type === 'url' && (
              <>
                <div className="image-content-cards" key={data.id}>
                {/* <div style={{margin:'0 10px'}}> */}
                 <input
                    type="checkbox"
                    name={data.name}
                    value={data.id}
                    checked={data.selected}
                    onChange={checkBox}
                    id={`checkbox${data.id}`}
                    style={{height:'15px',width:'15px',display:'none'}}
                    hidden
                  />
                 {/* </div> */}
                  <label htmlFor={`checkbox${data.id}`} className="upper-layer">
                  </label>
                    <div className="image-content-card-image check-tick">
                      {/* <iframe src={`${data.url}`} title={data.id}/> */}
                      <div style={{height:'100%',width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>

                      <LanguageOutlined
                sx={{
                  fontSize: "17px",
                  height: "60%",
                  width: "60%",
                  // color:"var(--primary-color)"
                }}
              />
                      </div>
                    </div>
                    <div className="image-content-card-footer">
                      <p>{data.name.substring(0,20)}</p>
                    </div>
                </div>
              </>
            )
        )}
      </div>
    </>
  );
};
export default Url;
