import React,{useState} from 'react'
import './text.css';
function Text({onSelectText,onAlignLeft,onAlignRight}) {
    const [textProperties,setTextProperties]=useState({
        textLeft:false,
        textCenter:false,
        textRight:false,
        textColor:'',
        textFontSize:'',
    })
  return (
    <div className='text-component-container'>
        <div className="text-fields">
      <button className='add-text-btn' onClick={onSelectText}>Add text</button>
        </div>
        <div className="text-fields">
            <button onClick={()=>onAlignRight("right")}>Right</button>
            <button onClick={()=>onAlignLeft("left")}>left</button>
        </div>
    </div>
  )
}

export default Text
