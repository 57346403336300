import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Radio,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createPacket } from "../../Redux/Actions/packetAction";

function CreatePacketModel({ open, onClose }) {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    name: "",
    ip: "",
    hexcode: "",
    tcp: false,
    udp: false,
  });
  const handleInput = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };
  const handleRadioInput = (e) => {
    const { name, id } = e.target;
    if (id == "tcp") {
      setForm((prev) => ({ ...prev, [id]: true, udp: false }));
    }
    if (id == "udp") {
      setForm((prev) => ({ ...prev, [id]: true, tcp: false }));
    }
  };
  return (
    <>
      <Dialog open={open} onClose={() => onClose()} maxWidth={"md"}>
        <DialogTitle>Add Packet</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              minHeight: "30vh",
              width: "40vw",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <TextField
              placeholder="Enter Name"
              name="name"
              value={form.name}
              onChange={handleInput}
            />
            <TextField
              placeholder="Enter IP"
              name="ip"
              value={form.ip}
              onChange={handleInput}
              fullWidth
            />
            <TextField
              placeholder="Enter HEXCODE"
              name="hexcode"
              value={form.hexcode}
              onChange={handleInput}
              fullWidth
            />
            <Box
              sx={{
                height: "10%",
                width: "100%",
              }}
            >
              <input
                type="radio"
                name={"network"}
                id={"tcp"}
                style={{ height: "20px", width: "20px" }}
                onChange={handleRadioInput}
              />{" "}
              TCP
              <input
                type="radio"
                name={"network"}
                id="udp"
                style={{ height: "20px", width: "20px" }}
                onChange={handleRadioInput}
              />{" "}
              UDP
            </Box>
            <button
              style={{
                height: "30px",
                width: "100px",
                background: "var(--primary-color)",
                color: "white",
              }}
              onClick={() => {
                dispatch(createPacket(form))
                onClose();
              }}
            >
              Save
            </button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CreatePacketModel;
