import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { _EditFolder, CreateFolder } from "../../Redux/Actions/folderAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { api } from "../../api/api";

function FolderModel({
  open,
  onClose,
  module,
  parent,
  userId,
  isEditMode,
  folderId,
}) {
  const { users, _folders } = useSelector(
    (state) => ({
      users: state.User.users,
      _folders: state.Folder.folders.find(
        (f) => f._id == folderId
      ),
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const [folderName, setFolderName] = useState("");
  const [predecessor, setPredecessor] = useState([]);
  const [delList, setDelList] = useState([]);
  const [user, setUser] = useState([]);
  useEffect(() => {
    if (isEditMode) {
      setFolderName(_folders?.name);
      let __user = [];
      let ___user = {};
      _folders?.predecessor?.forEach(({ user }) => {
        ___user[user._id] = user.username;
      });
      users.forEach((user) => {
        if (___user[user?._id] == user?.username) {
          __user.push({
            _id: user?._id,
            username: user?.username,
            role: user.role,
            isAssigned: true,
          });
        } else {
          __user.push({
            _id: user._id,
            username: user.username,
            role: user.role,
            isAssigned: false,
          });
        }
      });
      const _pre_ids = __user.filter((u) => u.isAssigned == true);
      setPredecessor(_pre_ids.map((u) => u._id));
      setUser(__user);
    } else {
      setUser(
        users.map((u) => ({
          _id: u._id,
          username: u.username,
          role: u.role,
          isAssigned: false,
        }))
      );
    }
  }, [isEditMode]);
  // console.log(_folders)
  const onSubmitFolder = () => {
    if (isEditMode) {
      dispatch(
        _EditFolder(folderId, { name: folderName, predecessor, delList })
      );
    } else {
      dispatch(
        CreateFolder({
          name: folderName,
          module,
          parent,
          user: userId,
          predecessor,
        })
      );
    }
    onClear();
  };
  const onClear = () => {
    onClose();
    setFolderName("");
    setPredecessor([]);
    setUser([]);
  };
  const onGetValue = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setUser(
        user.map((u) => (u._id == value ? { ...u, isAssigned: true } : u))
      );
      setPredecessor((prev) => [...prev, value]);
      setDelList((prev) => [prev.filter((d) => d != value)]);
    } else {
      setUser(
        user.map((u) => (u._id == value ? { ...u, isAssigned: false } : u))
      );
      setPredecessor((prev) => prev.filter((p) => p != value));
      setDelList((prev) => [...prev, value]);
    }
  };
  const onSelectAll=(e)=>{
    const { checked, value } = e.target;
    if (checked) {
      setUser(
        user.map((u) => ({ ...u, isAssigned: true }))
      );
      setPredecessor(user.map(u=>u._id));
      setDelList([]);
    } else {
      setUser(
        user.map((u) => ( { ...u, isAssigned: false }))
      );
      setPredecessor([]);
      setDelList(user.map(u=>u._id));
    }
  }
  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={open}
        onClose={() => {
          onClear();
        }}
      >
        <DialogTitle>Create Folder</DialogTitle>
        <DialogContent>
          <div style={{ margin: "20px 0" }}>
            <div
              style={{
                display: "flex",
                gap: "10px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                variant="standard"
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
                label="Title"
                fullWidth
                sx={{
                  label: {
                    color: "blue",
                    fontSize: "14px",
                    color: "#92959E",
                  },
                }}
              />

              <Box
                sx={{
                  maxHeight: "40vh",
                  width: "20dvw",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  overflowY: "auto",
                }}
              >
                <Box
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  gap={"10px"}
                >
                  <input
                    type={"checkbox"}
                    onChange={onSelectAll}
                    style={{ height: "20px", width: "20px" }}
                  />
                  <span>Select all</span>
                </Box>
                {user?.map((_, _index) => (
                  <>
                    <Box
                      display={"flex"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      gap={"10px"}
                    >
                      <input
                        type={"checkbox"}
                        name={_?.username}
                        value={_?._id}
                        onChange={(e) => onGetValue(e)}
                        // onChange={(e)=>onGetScreenAssign(e,'unfiltered')}
                        checked={_.isAssigned}
                        style={{ height: "20px", width: "20px" }}
                      />
                      <span style={{ display: "flex", gap: "5px" }}>
                        {_?.username}
                        {<span>({_?.role})</span>}
                      </span>
                    </Box>
                  </>
                ))}
              </Box>

              <button
                style={{ marginTop: "40px" }}
                className="btn-save-content"
                onClick={onSubmitFolder}
              >
                Create
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default FolderModel;
