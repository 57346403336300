import { Popover } from "@mui/material";
import React, { useState } from "react";
import { SketchPicker } from "react-color";
import FunctionsIcon from "@mui/icons-material/Functions";
function ShapesProperty({ shapeProps }) {
  const [colorAncherEl, setColorAncherEl] = useState(null);
  const [funcAncherEl, setFuncAncherEl] = useState(null);
  const [borderAncherEl, setBorderAncherEl] = useState(null);
  const [canvaColor, setCanvaColor] = useState("white");
  const [borderColor, setBorderColor] = useState("transparent");
  const colorOpen = Boolean(colorAncherEl);
  const colorId = colorOpen ? "simple-popover" : undefined;
  const funcOpen = Boolean(funcAncherEl);
  const funcId = funcOpen ? "simple-popover" : undefined;
  const borderOpen = Boolean(borderAncherEl);
  const borderId = borderOpen ? "simple-popover" : undefined;
  const handleColorClick = (event) => {
    setColorAncherEl(event.currentTarget);
  };
  const handleColorClose = () => {
    setColorAncherEl(null);
  };
  const handleFuncClick = (event) => {
    setFuncAncherEl(event.currentTarget);
  };
  const handleFuncClose = () => {
    setFuncAncherEl(null);
  };
  const handleBorderColorClick = (event) => {
    setBorderAncherEl(event.currentTarget);
  };
  const handleBorderColorClose = () => {
    setBorderAncherEl(null);
  };
  const onChangeColorComplete = (color) => {
    setCanvaColor(color.hex);
    shapeProps.onChangeColorCompleteOfShape(color.hex);
  };
  const onChangeBorderColorComplete = (color) => {
    setBorderColor(color.hex);
    shapeProps.onChangeBorderColorCompleteOfShape(color.hex);
  };
  return (
    <div className="shape-tool-container">
      <div className="shape-tool">
        <button
          style={{
            background: canvaColor,
            border: "1px solid black",
            height: "39px",
            width: "39px",
          }}
          aria-describedby={colorId}
          onClick={handleColorClick}
        ></button>
        <Popover
          id={colorId}
          open={colorOpen}
          anchorEl={colorAncherEl}
          onClose={handleColorClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <SketchPicker
            color={canvaColor}
            onChangeComplete={onChangeColorComplete}
          />
        </Popover>
      </div>
      <div className="shape-tool">
        <button
        style={{background:'white'}}
        aria-describedby={funcId} 
        onClick={handleFuncClick}>
          <FunctionsIcon style={{color:'black'}} fontSize="large" />
        </button>
        <Popover
          id={funcId}
          open={funcOpen}
          anchorEl={funcAncherEl}
          onClose={handleFuncClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div
            style={{
              minHeight: "20vh",
              width: "20vw",
              display: "flex",
              flexDirection: "column",
              gap: 5,
            }}
          >
            <div className="shape-inside-popover">
              <span>Border Color</span>
              <button
                style={{
                  background: borderColor,
                  border: "1px solid black",
                  height: "39px",
                  width: "39px",
                }}
                aria-describedby={funcId}
                onClick={handleBorderColorClick}
              ></button>
              <Popover
                id={borderId}
                open={borderOpen}
                anchorEl={funcAncherEl}
                onClose={handleBorderColorClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <SketchPicker
                  color={borderColor}
                  onChangeComplete={onChangeBorderColorComplete}
                />
              </Popover>
            </div>
            <div className="shape-inside-popover">
              <span>Thinkness</span>
              <input
                type={"range"}
                min={1}
                max={5}
                onChange={(e) =>
                  shapeProps.onChangeBorderThicknessOfShape(e.target.value)
                }
              />
            </div>
            <div className="shape-inside-popover">
              <span>Rounded</span>
              <input
                type={"range"}
                min={1}
                max={50}
                onChange={(e) =>
                  shapeProps.onChangeBorderRoundedOfShape(e.target.value)
                }
              />
            </div>
          </div>
        </Popover>
      </div>
    </div>
  );
}

export default ShapesProperty;
