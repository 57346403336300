import { InfoOutlined } from "@mui/icons-material";
import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import {
  _ContentFolderDelete,
  _DeleteFolder,
} from "../../Redux/Actions/folderAction";
import { useDispatch } from "react-redux";

function AlertModel({
  open,
  _module,
  selectedRow,
  onClose,
  icon,
  content,
  title,
  id,
}) {
  const dispatch = useDispatch();
  return (
    <>
      <Dialog open={open} onClose={() => onClose()} maxWidth={"lg"}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              height: "10vh",
              width: "25vw",
              display: "flex",
              justifyContent: "flex-start",
              gap: "5px",
            }}
          >
            <InfoOutlined />
            <span style={{ fontSize: "20px" }}>{content}</span>
          </Box>
          <Box
            sx={{
              height: "10vh",
              width: "25vw",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <button
              style={{
                height: "30px",
                width: "70px",
                background: "var(--primary-color)",
                color: "white",
              }}
              onClick={() => {
                if (_module == "screen") {
                  dispatch(_DeleteFolder(id));
                }
                if (_module == "content") {
                  dispatch(_ContentFolderDelete(id, selectedRow));
                  //  console.log(selectedRow)
                }
                onClose();
              }}
            >
              Yes
            </button>
            <button
              style={{
                height: "30px",
                width: "70px",
                background: "var(--primary-color)",
                color: "white",
              }}
              onClick={() => onClose()}
            >
              No
            </button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AlertModel;
