import {
  ADD_CONTENT_VALUE,
  EMPTY_CONTENT_FORM,
  COPY_CONTENT_SUCCESS,
  DELETE_CONTENT_VALUE,
} from "../constant";

export const addContent = (id, data) => {
  // alert(data)
  return async (dispatch) => {
    dispatch({ type: ADD_CONTENT_VALUE, payload: { id, data } });
  };
};
export const deleteContent = (pid, id) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_CONTENT_VALUE, payload: { pid, id } });
  };
};
export const emptyContent = () => {
  return async (dispatch) => {
    dispatch({
      type: EMPTY_CONTENT_FORM,
      payload: "",
    });
  };
};
export const copyContent = (content) => {
  return async (dispatch) => {
    dispatch({
      type: COPY_CONTENT_SUCCESS,
      payload: content,
    });
  };
};
