import { api } from "../../api/api";
import { COMPANY_DELETE_SUCCESS, GET_COMPANY_FAILED, GET_COMPANY_REQUEST, GET_COMPANY_SUCCESS, RESET_COMPANY } from "../constant";

export const getCompany = (id) => {
    return async (dispatch) => {
      dispatch({
        type: GET_COMPANY_REQUEST,
        payload: "",
      });
      const user = await api.get(`/user/getcompanies/${id}`);
      if (user.status === 200) {
        if (user.data.users.length > -1) {
          dispatch({
            type: GET_COMPANY_SUCCESS,
            payload: user.data.users,
          });
        } else {
          dispatch({
            type: GET_COMPANY_FAILED,
            payload: "",
          });
        }
      }
    };
  };

  export const getCompaniesBySuperadmin = () => {
    return async (dispatch) => {
      dispatch({
        type: GET_COMPANY_REQUEST,
        payload: "",
      });
      const user = await api.get(`/user/getCompaniesBySuperadmin`);
      if (user.status === 200) {
        if (user.data.users.length > -1) {
          dispatch({
            type: GET_COMPANY_SUCCESS,
            payload: user.data.users,
          });
        } else {
          dispatch({
            type: GET_COMPANY_FAILED,
            payload: "",
          });
        }
      }
    };
  };

  export const deleteCompany = (id) => {
    return async (dispatch) => {
      const user = await api.get(`/user/deleteuser/${id}`);
      // console.log(user)
      if (user.status === 201) {
        dispatch({
          type: COMPANY_DELETE_SUCCESS,
          payload: id,
        });
      }
    };
  };
  export const resetCompany=()=>{
    return async (dispatch)=>{
      dispatch({
        type:RESET_COMPANY,
        payload:''
      })
    }
  }