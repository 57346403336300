import React, { useState } from "react";
import PhotoCameraBackIcon from "@mui/icons-material/PhotoCameraBack";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { imgUrl } from "../../../api/api";
import { ReactComponent as JpgIcon } from "../../../Assets/images/jpgIcon.svg";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu, MenuItem } from "@mui/material";
import { Document, Page } from "react-pdf";
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
function Pdf({ data, addSchedule, deleteSingleContent }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  return (
    <>
      <div className="content-item" key={data.key}>
        <div className="content-image">
          <div className="content-del-icon">
            <DeleteOutlineIcon
              onClick={() => deleteSingleContent(data.id)}
              style={{ color: "var(--primary-color)" }}
            />
          </div>
           <div style={{ height: "230px", width: "230px",display:"flex",justifyContent:"center",alignItems:"center" }}>
          <PictureAsPdfOutlinedIcon 
            sx={{
              height:'80%',
              width:'80%',

            }}
          />
          </div> 
          {/* <img src={`${imgUrl}/${data.url}`} alt="" /> */}
        </div>
        <div className="content-bottom">
          <p>{data.name?.substring(0, 20)}</p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span>
              <PictureAsPdfOutlinedIcon
                sx={{
                  fontSize: "17px",
                  color:"var(--primary-color)"
                }}
              />
            </span>
            <span>{data.type=="application" && "Pdf"}</span>
          </p>
        </div>
      </div>
    </>
  );
}

export default Pdf;
