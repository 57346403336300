import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useState } from "react";

function AppWidget({ isOpen, onClose, isFullWidth, maxWidth,onOpenWidgetForm }) {
    const [listOfApps,setListOfApps]=useState([
        {
          name:'Youtube',
          key:'youtube',
          icon:'/images/youtubeIcon.png'
        },
        {
            name:'Youtube/Playlist',
            key:'youtubeplaylist',
            icon:'/images/youtubePlaylistIcon.png'
          },
        {
          name:'Embed',
          key:'embed',
          icon:'/images/embedIcon.png'
        },
        {
          name:'Weather',
          key:'weather',
          icon:'/images/weatherIcon.png'
        }
      ])
  return (
    <>
      <Dialog
        maxWidth={maxWidth}
        fullWidth={isFullWidth}
        open={isOpen}
        onClose={onClose}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "3px solid var(--primary-color)",
            fontSize: "18px",
            fontWeight: 700,
          }}
        >
          Add Widgets
        </DialogTitle>
        <DialogContent
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box
            sx={{
              minHeight: "30dvh",
              width: "30dvw",
              display: "flex",
              justifyContent:'center',
              alignItems:'center',
              margin: "10px 0",
              gap: 5,
              flexWrap:'wrap'
            }}
          >
            {
                listOfApps.map((_,index,listOfApps)=>(<>
            <Box
            onClick={()=>onOpenWidgetForm(_?.key)}
            key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "20vh",
                width: "230px",
                border: "1px solid black",
                borderRadius: 5,
                alignSelf:`${index===listOfApps.length-1 && 'flex-end'}`
              }}
            >
              <img
                src={_.icon}
                style={{ height: "80%", width: "55%" }}
              />
              <p>{_.name}</p>
            </Box>
                
                </>))
            }
           
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AppWidget;
