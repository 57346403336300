import {
  ADD_PLAYLIST_TO_SCREEN,
  ADD_SCREEN_REQUEST,
  ADD_SCREEN_SUCCESS,
  CLIENT_SCREENS_REQUEST,
  CLIENT_SCREENS_SUCCESS,
  CLOSE_CALENDER,
  DELETE_SCREEN,
  FIND_ONE_SCREEN,
  MOVE_SCREEN_TO_FOLDER_REQUEST,
  MOVE_SCREEN_TO_FOLDER_SUCCESS,
  OPEN_CALENDER,
  SCREEN_FILTER,
  SCREEN_FLAG_OFF,
  SCREEN_REQUEST,
  SCREEN_REQUEST_FAILED,
  SCREEN_REQUEST_SUCCESS,
  SCREEN_STATUS_ACTIVE,
  SCREEN_STATUS_DEACTIVE,
  UNCHECK_ASSIGNED_SCREEN,
  UPDATE_SCREEN_SUCCESS,
} from "../constant";
const initial = {
  screens: [],
  chunkScreens:[],
  screen: {},
  screenLoading: false,
  isScreenAdded: false,
  isScreenDeleted: false,
  activeScreens: 0,
  deactiveScreens: 0,
  isScreenMoved: false,
  isScreenMoving: false,
  isAdded: false,
  isScreenUpdated: false,
  isUnChecked: false,
  isScreenFetch:false,
  isScreenNotFetch:false,
  mac: "",
  clientScreens: [],
};

export const screenReducer = (state = initial, action) => {
  function findIndex(id) {
    let _index = state.screens.findIndex((_screen) => _screen._id == id);
    return _index;
  }
  switch (action.type) {
    case SCREEN_FLAG_OFF:
      return {
        ...state,
        isUnChecked: false,
        isAdded: false,
        isScreenDeleted: false,
        isScreenUpdated: false,
        isScreenMoved: false,
        isScreenMoving: false,
        isScreenFetch:false,
        isScreenNotFetch:false
      };
    case ADD_SCREEN_REQUEST:
      return {
        ...state,
        isScreenAdded: true,
      };
    case ADD_SCREEN_SUCCESS:
      let regiScreens = {};
      const now_2 = new Date().getTime();
      let till_2 = 60 * 1000;
      //  let distance=now + till;
      //  action.payload.forEach(row=> {
      //  if(row.isActive){
      if (now_2 > +action.payload?.lastActive + till_2) {
        regiScreens = { ...action.payload, onlinesStatus: false,isCalenderOpened:false };
        // offline_2+=1;
        state.deactiveScreens += 1;
      } else {
        regiScreens = { ...action.payload, onlinesStatus: true,isCalenderOpened:false };
        //  online_2+=1;
        state.activeScreens += 1;
      }
      return {
        ...state,
        screens: [...state.screens, regiScreens],
        chunkScreens: [...state.chunkScreens, regiScreens],
        activeScreens: state.activeScreens,
        deactiveScreens: state.deactiveScreens,
        isScreenAdded: false,
        isAdded: true,
        mac: action.payload.mac,
      };
    case UPDATE_SCREEN_SUCCESS:
      let updatedEditScreens = {};
      const now_1 = new Date().getTime();
      let till_1 = 60 * 1000;
      //  let distance=now + till;
      //  action.payload.forEach(row=> {
      //  if(row.isActive){
      if (Date.now() > +action.payload?.lastActive + till_1) {
        updatedEditScreens = { ...action.payload, onlinesStatus: false,isCalenderOpened:false };
        // offline_1+=1;
        state.deactiveScreens += 1;
      } else {
        updatedEditScreens = { ...action.payload, onlinesStatus: true,isCalenderOpened:false };
        //  online_1+=1;
        state.activeScreens += 1;
      }
      state.screens[findIndex(action.payload._id)] = { ...updatedEditScreens };
      return {
        ...state,
        activeScreens: state.activeScreens,
        deactiveScreens: state.deactiveScreens,
        screens: state.screens,
        chunkScreens:state.screens,
        mac: action.payload.mac,
        isScreenUpdated: true,
      };
    case SCREEN_REQUEST:
      return {
        ...state,
        screenLoading: true,
      };
    case SCREEN_REQUEST_SUCCESS:
      let newScreen = [];
      let updatedScreens = [];
      const now = new Date().getTime();
      let till = 60 * 1000;
      let online = 0;
      let offline = 0;
      //  let distance=now + till;
      action.payload.forEach((row) => {
        //  if(row.isActive){
        if (row?.lastActive !== undefined) {
          if (Date.now() > +row?.lastActive + till) {
            updatedScreens.push({ ...row, onlinesStatus: false ,isCalenderOpened:false});
            offline += 1;
          } else {
            updatedScreens.push({ ...row, onlinesStatus: true,isCalenderOpened:false });
            online += 1;
          }
        }
        // }
      });
      
      return {
        ...state,
        screenLoading: false,
        isScreenFetch:true,
        screens: updatedScreens,
        chunkScreens:updatedScreens,
        activeScreens: online,
        deactiveScreens: offline,
      };
    case SCREEN_REQUEST_FAILED:
      return {
        ...state,
        screenLoading: false,
        isScreenNotFetch:true,
        screens:[]
      };
    case FIND_ONE_SCREEN:
      return {
        ...state,
        screen: {
          ...state.screens.find((s) => s._id === action.payload),
          screenAssigned: false,
        },
      };
    case ADD_PLAYLIST_TO_SCREEN:
      // const assingnedScreenIndex = state.screens.findIndex(s => s._id === action.payload._id);
      state.screens[findIndex(action.payload._id)] = {
        ...action.payload,
        screenAssigned: false,
      };
      return {
        ...state,
        screens: state.screens,
        isUnChecked: true,
      };
    case UNCHECK_ASSIGNED_SCREEN:
      const findUnCheckScreen = findIndex(action.payload);
      state.screens[findUnCheckScreen] = {
        ...state.screens[findUnCheckScreen],
        screenAssigned: true,
      };
      return {
        ...state,
        screens: state.screens,
        isUnChecked: true,
      };

    case SCREEN_STATUS_ACTIVE:
      // const activeStatusIndex = state.screens.findIndex(s => s._id === action.payload._id)
      state.screens[findIndex(action.payload._id)] = { ...action.payload };
      return {
        ...state,
        screens: state.screens,
      };
    case SCREEN_STATUS_DEACTIVE:
      // const DeactiveStatusIndex = state.screens.findIndex(s => s._id === action.payload._id)
      state.screens[findIndex(action.payload._id)] = { ...action.payload };
      return {
        ...state,
        screens: state.screens,
      };
    case DELETE_SCREEN:
      return {
        ...state,
        isScreenDeleted: true,
        screens: state.screens.filter(
          (screen) => screen._id !== action.payload
        ),
      };
    case CLIENT_SCREENS_REQUEST:
      return {
        ...state,
        screenLoading: true,
      };
    case CLIENT_SCREENS_SUCCESS:
      let newClientScreens = [];
      action.payload.forEach((s) => {
        newClientScreens.push({ ...s, screenAssigned: true });
      });
      return {
        ...state,
        screenLoading: false,
        clientScreens: newClientScreens,
      };
    case MOVE_SCREEN_TO_FOLDER_REQUEST:
      return {
        ...state,
        isScreenMoving: true,
      };
    case MOVE_SCREEN_TO_FOLDER_SUCCESS:
      const __screen_move_index = state.screens.findIndex(
        (s) => s._id == action.payload._id
      );
      state.screens[__screen_move_index] = { ...action.payload };
      return {
        ...state,
        isScreenMoved: true,
        screens: state.screens,
      };
      case SCREEN_FILTER:
        let _screens;
        // console.log(state.screens)
        // console.log(state.chunkScreens.filter(s=>s.onlinesStatus==false))
        if(action.payload=='all'){
          _screens=state.chunkScreens.filter(s=>s!='');
        }
        else if(action.payload=='offline'){
          _screens=state.chunkScreens.filter(s=>(!s.onlinesStatus && s.isLicensed && Date.now() < new Date(s.licenseTill)));
        }else if(action.payload=='online'){
          _screens=state.chunkScreens.filter(s=>(s.onlinesStatus && s.isLicensed && Date.now() < new Date(s.licenseTill)));
        }else if(action.payload=='pending'){
          _screens=state.chunkScreens.filter(s=>s.isLicensed==false)
        }else{
          _screens=state.chunkScreens.filter(s=> Date.now() > new Date(s.licenseTill));
        }
        // console.log(_screens)
        return {
          ...state,
          screens:_screens
        }
        case OPEN_CALENDER:
          const _indexCalender=state.screens.findIndex(s=>s._id==action.payload);
          state.screens[_indexCalender]={...state.screens[_indexCalender],isCalenderOpened:true}
          return {
            ...state,
            screens:state.screens
          }
          case CLOSE_CALENDER:
            const __indexCalender=state.screens.findIndex(s=>s._id==action.payload);
            state.screens[__indexCalender]={...state.screens[__indexCalender],isCalenderOpened:false}
            return {
              ...state,
              screens:state.screens
            }
    default:
      return state;
  }
};
