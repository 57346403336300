import React from 'react'
import "./loader.css";
function MiniLoader() {
  return (
    <div className='mini-loader'>
    </div>
  )
}

export default MiniLoader
