import {io} from 'socket.io-client';
export const socketInit=()=>{
    const options={
        'force new connection':true,
        reconnection: true,          // Enable reconnection
  reconnectionDelay: 1000,
  reconnectionDelayMax: 1000,
        reconnectionAttempt:'Infinity',
        timeout:10000,
        transport:['websocket'],
    }
    return io(`${process.env.REACT_APP_URL_IMAGE}`,options);
    } 