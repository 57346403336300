import React from 'react'
import "./loader.css";
function Loader() {
  return (
    <div className='loader'>
    </div>
  )
}

export default Loader
