import {
  GET_PARTNER_FAILED,
  GET_PARTNER_REQUEST,
  GET_PARTNER_SUCCESS,
  PARTNER_DELETE_SUCCESS,
  PARTNER_SAVE_FAILED,
  PARTNER_SAVE_SUCCESS,
  RESET_PARTNER,
} from "../constant";

const initial = {
  partners: [],
  isPartnerLoading: false,
  isPartnerAdded: false,
  isPartnerDelete:false,
        isPartnerAlreadyAvailable: false,
};

export const PartnerReducer = (state = initial, action) => {
  switch (action.type) {
    case RESET_PARTNER:
      return {
        ...state,
        isPartnerLoading: false,
        isPartnerAdded: false,
        isPartnerDelete:false,
              isPartnerAlreadyAvailable: false,
      }
    case PARTNER_SAVE_SUCCESS:
      return {
        ...state,
        partners: [...state.partners, action.payload].reverse(),
        isPartnerAdded: true,
        isPartnerAlreadyAvailable: false,
      };
    case PARTNER_SAVE_FAILED:
      return {
        ...state,
        isPartnerAdded: false,
        isPartnerAlreadyAvailable: true,
      };
    case GET_PARTNER_REQUEST:
      return {
        ...state,
        isPartnerLoading: true,
      };
    case GET_PARTNER_SUCCESS:
      return {
        ...state,
        isPartnerLoading: false,
        partners: [...action.payload].reverse(),
      };
    case GET_PARTNER_FAILED:
      return {
        ...state,
        isPartnerLoading: false,
      };
      case PARTNER_DELETE_SUCCESS:
        return {
          ...state,
          isPartnerDelete: true,
          partners: state.partners.filter(u => u._id !== action.payload)
        }
    default:
      return state;
  }
};
