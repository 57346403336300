import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { contentUpdate } from "../../../../Redux/Actions/contentAction";
import { useDispatch } from "react-redux";

function Clock({
  open,
  onClose,
  onClockSubmit,
  onClockEdit,
  isClockEdit,
  clockEditObj,
}) {
  const dispatch = useDispatch();
  const [clockData, setClockData] = useState({
    type: "clock",
    title: "",
    // link:'',
    mDialColor: "",
    mPointColor: "",
    mHourColor: "",
    mMintureColor: "",
    mSecondColor: "",
  });
  useEffect(() => {
    if (isClockEdit) {
      setClockData({
        type: "clock",
        title: clockEditObj.name,
        // link:'',
        mDialColor: clockEditObj.mDialColor,
        mPointColor: clockEditObj.mPointColor,
        mHourColor: clockEditObj.mHourColor,
        mMinuteColor: clockEditObj.mMinuteColor,
        mSecondColor: clockEditObj.mSecondColor,
      });
    }
  }, [isClockEdit]);

  const _onClose = () => {
    onClose();
    setClockData({
      type: "clock",
      title: "",
      // link:'',
      mDialColor: "",
      mPointColor: "",
      mHourColor: "",
      mMinuteColor: "",
      mSecondColor: "",
    });
  };
  const _onClockSubmit = (obj) => {
    onClockSubmit(obj);
    setClockData({
      type: "clock",
      title: "",
      // link:'',
      mDialColor: "",
      mPointColor: "",
      mHourColor: "",
      mMinuteColor: "",
      mSecondColor: "",
    });
  };
  const _onClockEdit = (obj) => {
    obj.name=obj.title
    delete obj.title
    dispatch(contentUpdate(clockEditObj.id, obj));
    onClose();
    setClockData({
      type: "clock",
      title: "",
      // link:'',
      mDialColor: "",
      mPointColor: "",
      mHourColor: "",
      mMinuteColor: "",
      mSecondColor: "",
    });
  };
  return (
    <>
      <Dialog open={open} onClose={_onClose} maxWidth={"lg"}>
        <DialogTitle>Clock</DialogTitle>
        <DialogContent>
          <Box sx={{ minHeight: "40vh", width: "40vw" }}>
            <div style={{ margin: "20px 0" }}>
              <Typography variant={"h6"} textAlign={"center"}>
                Clock Widget Form
              </Typography>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  required
                  variant="standard"
                  name={"title"}
                  value={clockData.title}
                  onChange={(e) =>
                    setClockData((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }))
                  }
                  label="Title"
                  fullWidth
                  sx={{
                    label: {
                      color: "blue",
                      fontSize: "14px",
                      color: "#92959E",
                    },
                  }}
                />
                {/* <TextField
                required
                  variant="standard"
                  name={'link'}
                  value={clockData.link}
                  onChange={(e) => setClockData((prev)=>({...prev,[e.target.name]:e.target.value}))}
                  label="Url Link"
                  fullWidth
                  sx={{
                    label: {
                      color: "blue",
                      fontSize: "14px",
                      color: "#92959E",
                    },
                  }}
                /> */}
                <TextField
                  type={"color"}
                  required
                  variant="standard"
                  name={"mDialColor"}
                  value={clockData.mDialColor}
                  onChange={(e) =>
                    setClockData((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }))
                  }
                  label="Dial Color"
                  fullWidth
                  sx={{
                    label: {
                      color: "blue",
                      fontSize: "14px",
                      color: "#92959E",
                    },
                  }}
                />
                <TextField
                  type={"color"}
                  required
                  variant="standard"
                  name={"mPointColor"}
                  value={clockData.mPointColor}
                  onChange={(e) =>
                    setClockData((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }))
                  }
                  label="Point Color"
                  fullWidth
                  sx={{
                    label: {
                      color: "blue",
                      fontSize: "14px",
                      color: "#92959E",
                    },
                  }}
                />
                <TextField
                  type={"color"}
                  required
                  variant="standard"
                  name={"mHourColor"}
                  value={clockData.mHourColor}
                  onChange={(e) =>
                    setClockData((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }))
                  }
                  label="Hour Color"
                  fullWidth
                  sx={{
                    label: {
                      color: "blue",
                      fontSize: "14px",
                      color: "#92959E",
                    },
                  }}
                />
                <TextField
                  type={"color"}
                  required
                  variant="standard"
                  name={"mMinuteColor"}
                  value={clockData.mMinuteColor}
                  onChange={(e) =>
                    setClockData((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }))
                  }
                  label="Minute Color"
                  fullWidth
                  sx={{
                    label: {
                      color: "blue",
                      fontSize: "14px",
                      color: "#92959E",
                    },
                  }}
                />
                <TextField
                  type={"color"}
                  required
                  variant="standard"
                  name={"mSecondColor"}
                  value={clockData.mSecondColor}
                  onChange={(e) =>
                    setClockData((prev) => ({
                      ...prev,
                      [e.target.name]: e.target.value,
                    }))
                  }
                  label="Second Color"
                  fullWidth
                  sx={{
                    label: {
                      color: "blue",
                      fontSize: "14px",
                      color: "#92959E",
                    },
                  }}
                />
                <button
                  style={{ marginTop: "40px" }}
                  className="btn-save-content"
                  onClick={() =>
                    isClockEdit
                      ? _onClockEdit(clockData)
                      : _onClockSubmit(clockData)
                  }
                >
                  Save
                </button>
              </div>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Clock;
