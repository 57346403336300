import axios from 'axios';
import React from 'react'
import {useDispatch} from 'react-redux';
import { baseApi } from '../api/api';
import { refreshLogin } from '../Redux/Actions/userAction';
export const useRefreshLogin=()=>{
const [loading,setLoading]=React.useState(true);
const dispatch=useDispatch();
React.useEffect(()=>{
    (async () => {
        try {
            const { status,data } = await axios.get(
                `${baseApi}/user/refresh`,
                {
                    withCredentials: true,
                }
            );
            dispatch(refreshLogin(status,data));
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    })();
},[])
return {loading}
}
