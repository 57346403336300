import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Radio,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  assignScreenToPackets,
  updatePacket,
} from "../../Redux/Actions/packetAction";

function AssignScreenToPacketModel({ open, id, onClose, editMode }) {
  const dispatch = useDispatch();
  const { screens, packets } = useSelector(
    (state) => ({
      screens: state.Screen.screens,
      packets: state.Packet.packets,
    }),
    shallowEqual
  );
  const [form, setForm] = useState({
    name: "",
    screen: [],
  });
  useEffect(() => {
    setForm({ name: "", screen: [] });
    if (id) {
      const p = packets.find((_p) => _p._id == id);
      let screenMap = {};
      if (p.screen.length > 0) {
        p.screen.forEach((_) => {
          screenMap[_.name] = true;
        });

        for (let i in screens) {
          if (screenMap[screens[i].name]) {
            setForm((prev) => ({
              ...prev,
              screen: [
                ...prev.screen,
                { ...screens[i], checked: true, isAssigned: true },
              ],
            }));
          } else {
            setForm((prev) => ({
              ...prev,
              screen: [
                ...prev.screen,
                { ...screens[i], checked: false, isAssigned: false },
              ],
            }));
          }
        }
      } else {
        setForm({
          name: p?.name,
          screen: screens,
        });
      }
    }
  }, [id]);
  const handleInput = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };
  const handleRadioInput = (e) => {
    const { name, id } = e.target;
    if (id == "tcp") {
      setForm((prev) => ({ ...prev, [id]: true, udp: false }));
    }
    if (id == "udp") {
      setForm((prev) => ({ ...prev, [id]: true, tcp: false }));
    }
  };
  const onSelectAll = (e) => {
    if (e.target.checked) {
      setForm({
        name: form.name,
        screen: form.screen.map((s) => ({
          ...s,
          isAssigned: true,
          checked: true,
        })),
      });
    } else {
      setForm({
        name: form.name,
        screen: form.screen.map((s) => ({
          ...s,
          isAssigned: false,
          checked: false,
        })),
      });
    }
  };
  const _onClose = () => {
    onClose();
  };
  const onGetValue = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setForm((prev) => ({
        ...prev,
        screen: [
          ...prev.screen.map((s) =>
            s.name == name ? { ...s, isAssigned: true, checked: true } : s
          ),
        ],
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        screen: [
          ...prev.screen.map((s) =>
            s.name == name ? { ...s, isAssigned: false, checked: false } : s
          ),
        ],
      }));
    }
  };
  const onSubmit = () => {
    let src = [];
    form.screen.forEach((e) => {
      if (e.checked) {
        src.push(e._id);
      }
    });
    console.log(src);
    dispatch(assignScreenToPackets({ screenid: id, screen: src }));
    _onClose();
  };
  return (
    <>
      <Dialog open={open} onClose={() => _onClose()} maxWidth={"md"}>
        <DialogTitle>Assign to screens</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              minHeight: "30vh",
              width: "40vw",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <TextField
              placeholder="Enter Name"
              name="name"
              value={form.name}
              onChange={handleInput}
            />

            <Box
              sx={{
                maxHeight: "40vh",
                width: "20dvw",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                overflowY: "auto",
              }}
            >
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                gap={"10px"}
              >
                <input
                  type={"checkbox"}
                  onChange={onSelectAll}
                  style={{ height: "20px", width: "20px" }}
                />
                <span>Select all</span>
              </Box>
              {form?.screen?.map((_, _index) => (
                <>
                  <Box
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    gap={"10px"}
                  >
                    <input
                      type={"checkbox"}
                      name={_?.name}
                      value={_?._id}
                      onChange={(e) => onGetValue(e)}
                      checked={_.isAssigned}
                      style={{ height: "20px", width: "20px" }}
                    />
                    <span style={{ display: "flex", gap: "5px" }}>
                      {_?.name}
                    </span>
                  </Box>
                </>
              ))}
            </Box>
            <button
              style={{
                height: "30px",
                width: "100px",
                background: "var(--primary-color)",
                color: "white",
              }}
              onClick={() => {
                onSubmit();
              }}
            >
              Update
            </button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AssignScreenToPacketModel;
