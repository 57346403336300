import React,{useState} from 'react'
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { imgUrl } from '../../../api/api';
import { ReactComponent as JpgIcon } from "../../../Assets/images/jpgIcon.svg";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem } from '@mui/material';
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import { PlayArrowOutlined, PlayCircleFilledOutlined, VideoCameraBack } from '@mui/icons-material';
function Vedio({ data,addSchedule, deleteSingleContent }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
 
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type, obj) => {
    setAnchorEl(null);
  };
  return (
    <>
      <div className="content-item" key={data.id}>
        <div className="content-image">
          <div className="content-del-icon">
          <DeleteOutlineIcon onClick={() => deleteSingleContent(data.id)} style={{ color: 'var(--primary-color)' }} />
          </div>
          <video key={data.id} controls onError={(e)=>console.log(e)}>
                <source src={`${data.url}`} type="video/mp4" />
                {/* Your browser does not support the video tag. */}
            </video>
          {/* <video src={`${data.url}`} controls /> */}
        </div>
        <div className="content-bottom">
          <p>{data?.name?.substring(0,20)}</p>
          <p style={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <span>
              <PlayCircleFilledOutlined
              sx={{
                fontSize: '17px',
                color:"var(--primary-color)"
              }}
              />
              {/* <JpgIcon sx={{
                fontSize: '17px'
              }} /> */}
            </span>
            <span>{data.type}</span>
          </p>
        </div>
      </div>
    </>
  )
}

export default Vedio