import {
  CREATE_PACKET_REQUEST,
  CREATE_PACKET_SUCCESS,
  CREATE_PACKET_FAILED,
  CLEAR_PACKET_STATE,
  GET_PACKET_REQUEST,
  GET_PACKET_SUCCESS,
  GET_PACKET_FAILED,
  PACKET_DELETE_REQUEST,
  PACKET_DELETE_SUCCESS,
  PACKET_UPDATE_REQUEST,
  PACKET_UPDATE_SUCCESS,
  ASSIGN_SCREEN_TO_PACKET_SUCCESS,
  ASSIGN_SCREEN_TO_PACKET_REQUEST,
  GET_PACKET_BY_SCREEN_SUCCESS,
} from "../constant";

const initial = {
  isRequest: false,
  isSuccess: false,
  isFailed: false,
  type: "",
  packets: [],
  screen_packets: [],
};

const PacketReducer = (state = initial, action) => {
  const getIndex = (packet, id) => {
    var _index = packet.findIndex((p) => p._id == id);
    return { _index };
  };
  switch (action.type) {
    case CLEAR_PACKET_STATE:
      return {
        ...state,
        isRequest: false,
        isSuccess: false,
        isFailed: false,
        type: "",
      };
    case CREATE_PACKET_REQUEST:
      return {
        ...state,
        isRequest: true,
      };

    case CREATE_PACKET_SUCCESS:
      return {
        ...state,
        isRequest: false,
        isSuccess: true,
        packets: [...state.packets, action.payload],
        type: CREATE_PACKET_SUCCESS,
      };

    case CREATE_PACKET_FAILED:
      return {
        ...state,
        isRequest: false,
        isFailed: true,
        type: CREATE_PACKET_FAILED,
      };

    case GET_PACKET_REQUEST:
      return {
        ...state,
        isRequest: true,
      };
    case GET_PACKET_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        packets: action.payload,
        type: GET_PACKET_SUCCESS,
      };
    case GET_PACKET_FAILED:
      return {
        ...state,
        isFailed: true,
      };
    case GET_PACKET_BY_SCREEN_SUCCESS:
      return {
        ...state,
        screen_packets: action.payload,
      };
    case PACKET_DELETE_REQUEST:
      return {
        ...state,
        isRequest: true,
      };
    case PACKET_DELETE_SUCCESS:
      return {
        ...state,
        packets: state.packets.filter((p) => p._id != action.payload),
        isSuccess: true,
        type: PACKET_DELETE_SUCCESS,
      };
    case PACKET_UPDATE_REQUEST:
      return {
        ...state,
        isRequest: true,
      };
    case PACKET_UPDATE_SUCCESS:
      const _packet_update_index = state.packets.findIndex(
        (p) => p._id == action.payload._id
      );
      state.packets[_packet_update_index] = { ...action.payload };
      return {
        ...state,
        packets: state.packets,
        isSuccess: true,
        type: PACKET_UPDATE_SUCCESS,
      };
    case ASSIGN_SCREEN_TO_PACKET_REQUEST:
      return {
        ...state,
        isRequest: true,
      };
    case ASSIGN_SCREEN_TO_PACKET_SUCCESS:
      let { _index } = getIndex(state.packets, action.payload._id);
      state.packets[_index] = action.payload;
      return {
        ...state,
        isSuccess: true,
        packets: state.packets,
        type: PACKET_UPDATE_SUCCESS,
      };
    default:
      return state;
  }
};

export default PacketReducer;
