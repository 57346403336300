import React, { useEffect } from 'react'
import Clear from '../../../../../Assets/icons/sun.png';
import Rainy from '../../../../../Assets/icons/rain.png';
import Cloudy from '../../../../../Assets/icons/cloud.png';
import Haze from '../../../../../Assets/icons/fog.png'
function MainCard({icon,weather}) {
  const imgDictionary={
    Clear:Clear,
    Rainy:Rainy,
    Clouds:Cloudy,
    Haze:Haze
  }
  return (
   <>
    <div className="weather-card">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  width: "90%",
                  margin: "0 auto",
                }}
              >
                {/* <img
                                src={`https://openweathermap.org/img/wn/${weather.icon}@2x.png`}
                                alt="icon"
                              /> */}
                <img
                  src={imgDictionary[weather.weather]}
                  style={{ height: "30%", width: "30%", objectFit: "cover" }}
                />
                <p
                  style={{
                    fontSize: "50px",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  {weather?.temp} C
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  width: "90%",
                  margin: "0 auto",
                  // border: "1px solid black",
                }}
              >
                <p
                  style={{
                    fontSize: "20px",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  {weather?.name}
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  gap: "10px",
                  width: "90%",
                  margin: "0 auto",
                  // border: "1px solid black",
                }}
              >
                <p
                  style={{
                    fontSize: "15px",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Sun jun 24 2023
                </p>
                <p
                  style={{
                    fontSize: "15px",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  6:30 AM
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  gap: "10px",
                  width: "90%",
                  margin: "0 auto",
                  // border: "1px solid black",
                }}
              >
                <div
                  style={{
                    height: "50px",
                    width: "150px",
                    background: "#1E88E5",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "5px",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  <span>Wind Speed</span>
                  <span>{weather?.wind} km/h</span>
                </div>
                <div
                  style={{
                    height: "50px",
                    width: "150px",
                    background: "#43A047",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "5px",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  <span>Humidity</span>
                  <span>{weather?.humidity} gm/m</span>
                </div>
              </div>
              <div style={{width:'90%',borderBottom:'1px solid white',margin:'10px auto'}}></div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  gap: "10px",
                  width: "90%",
                  margin: "0 auto",
                  // border: "1px solid black",
                }}
              >
                <p
                  style={{
                    fontSize: "15px",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  {weather?.weather}
                </p>
              </div>

              <line />
            </div>
   </>
  )
}

export default MainCard
