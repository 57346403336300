import { CREATE_FOLDER_SUCCESS, DELETE_CONTENT_FOLDER_SUCCESS, DELETE_FOLDER_SUCCESS, GET_FOLDER_SUCCESS, UPDATE_FOLDER_SUCCESS } from "../constant"

const initial={
    folders:[],
}
export const FolderReducer=(state=initial,action)=>{
    switch(action.type){
        case CREATE_FOLDER_SUCCESS:
            return {
                ...state,
                folders:[...state.folders,action.payload]
            }

            case GET_FOLDER_SUCCESS:
                return {
                    ...state,
                    folders:action.payload
                }
                case UPDATE_FOLDER_SUCCESS:
                    // console.log(action.payload)
                    const index=state.folders.findIndex(f=>f._id===action.payload._id);
                    // console.log(index)
                    state.folders[index]={...action.payload};
                    return {
                        ...state,
                        folders:state.folders
                    }
                    case DELETE_FOLDER_SUCCESS:
                        return {
                            ...state,
                            folders:state.folders.filter(f=>f._id!==action.payload)
                        }
                     
                default:
                    return state
    }
}