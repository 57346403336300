import React, { useState } from "react";
import PhotoCameraBackIcon from "@mui/icons-material/PhotoCameraBack";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  AppsOutlined,
  CloudCircleOutlined,
  LockClockOutlined,
  ModeEditOutline,
  RssFeedOutlined,
} from "@mui/icons-material";
import { imgUrl } from "../../../api/api";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Divider, Menu, MenuItem } from "@mui/material";
function Widget({
  data,
  addSchedule,
  deleteSingleContent,
  editContent,
  onEditClock,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type, obj) => {
    setAnchorEl(null);
  };
  return (
    <>
      <div className="content-item" key={data.key}>
        <div className="content-image">
          <div className="content-del-icon">
            <MoreVertIcon
              onClick={handleClick}
              style={{ color: "var(--primary-color)" }}
              // size="small"
              // sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            />
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
            >
              {data.type == "clock" ? (
                <MenuItem>
                  <ModeEditOutline
                    style={{ color: "var(--primary-color)" }}
                    onClick={() => onEditClock(data)}
                  />
                </MenuItem>
              ) : (
                <MenuItem>
                  <ModeEditOutline
                    style={{ color: "var(--primary-color)" }}
                    onClick={() => editContent(data)}
                  />
                </MenuItem>
              )}
              <Divider />
              <MenuItem>
                <DeleteOutlineIcon
                  onClick={() => deleteSingleContent(data.id)}
                  style={{ color: "var(--primary-color)" }}
                />
              </MenuItem>
            </Menu>
            {/* <DeleteOutlineIcon  onClick={() => deleteSingleContent(data.id)} style={{ color: 'var(--primary-color)' }} /> */}
          </div>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {data.type == "weather" && (
              <CloudCircleOutlined
                sx={{
                  fontSize: "17px",
                  height: "80%",
                  width: "80%",
                  // color:"var(--primary-color)"
                }}
              />
            )}
            {data.type == "clock" && (
              <LockClockOutlined
                sx={{
                  fontSize: "17px",
                  height: "80%",
                  width: "80%",
                  // color:"var(--primary-color)"
                }}
              />
            )}
            {data.type == "rss" && (
              <RssFeedOutlined
                sx={{
                  fontSize: "17px",
                  height: "80%",
                  width: "80%",
                  // color:"var(--primary-color)"
                }}
              />
            )}
          </div>
        </div>
        <div className="content-bottom">
          <p>{data.name?.substring(0, 20)}</p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span>
              {data.type == "weather" && (
                <CloudCircleOutlined
                  sx={{
                    fontSize: "17px",
                    color: "var(--primary-color)",
                  }}
                />
              )}
              {data.type == "clock" && (
                <LockClockOutlined
                  sx={{
                    fontSize: "17px",
                    color: "var(--primary-color)",
                  }}
                />
              )}
            </span>
            <span>{data.type}</span>
          </p>
        </div>
      </div>
      {/* <div className="content-item" key={data.key}>
        <div className="content-image">
          <div className="content-del-icon">
          <DeleteOutlineIcon  onClick={() => deleteSingleContent(data.id)} style={{ color: 'var(--primary-color)' }} />
          </div>
          <img src={`${data.widget.widgetIcon}`} style={{height:'100%',width:'100%',objectFit:'contain'}} alt="" />
        </div>
        <div className="content-bottom">
          <p>{data.name?.substring(0,20)}</p>
          <p style={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <span>
              <AppsOutlined sx={{
                fontSize: '17px',
                color:"var(--primary-color)"
              }} />
            </span>
            <span>{data.type}</span>
          </p>
        </div>
      </div> */}
    </>
  );
}

export default Widget;
