import {
  ADD_PLAYLIST_SUCCESS,
  DELETE_CONTENT_FROM_PLAYLIST,
  ADD_CONTENT_VALUE,
  GET_PLAYLIST_REQUEST,
  ADD_CONTENT_IN_PLAYLIST,
  PLAYLIST_SCREEN_CHECK,
  DELETE_PLAYLIST,
  ADD_PLAYLIST_REQUEST,
  GET_PLAYLIST_SUCCESS,
  DELETE_CONTENT_VALUE,
  UPDATE_DURATION,
  GET_PLAYLIST_FAILED,
  PLAYLIST_FLAG_OFF,
  PLAYLIST_SCHEDULE_SUCCESS,
  PLAYLIST_SCHEDULE_DELETE,
  CLIENT_PLAYLIST_SUCCESS,
  PLAYLIST_CONTNET_SORTING,
  PLAYLIST_TO_MULTIPLE_SCREEN,
  PLAYLIST_TO_MULTIPLE_SCREEN_SUCCESS,
  GET_FOLDER_PLAYLIST_SUCCESS,
  PLAYLIST_PERMISSION_SUCCESS,
  PLAYLIST_UPDATE_REQUEST,
  UPDATE_IMAGE_RESOLUTION,
} from "../constant";

const initial = {
  isPlaylistLoading: false,
  isPlaylistAdded: false,
  isPlaylistAdding: false,
  isPlaylistDeleted: false,
  isPlaylistUpdated: false,
  isPlaylistPermissionUpdated: false,
  isPlaylistPermissionUpdating:false,
  isPlaylistScheduled: false,
  isPlaylistScheduledDeleted: false,
  isPlalistAssign: false,
  type: "",
  isSorted: false,
  playlist: [],
  _playlist: [],
  content: [],
  clientPlaylist: [],
  _playlistId: "",
};
export const playlistReducer = (state = initial, action) => {
  switch (action.type) {
    case PLAYLIST_FLAG_OFF:
      return {
        ...state,
        isPlaylistAdded: false,
        isPlaylistDeleted: false,
        isPlaylistUpdated: false,
        isPlaylistScheduled: false,
        isPlaylistScheduledDeleted: false,
        isSorted: false,
        isPlalistAssign: false,
        isPlaylistPermissionUpdated:false,
        isPlaylistPermissionUpdating:false,
        type: "",
        _playlistId: "",
      };
    case ADD_PLAYLIST_REQUEST:
      return {
        ...state,
        isPlaylistAddeding: true,
      };
    case ADD_PLAYLIST_SUCCESS:
      return {
        ...state,
        isPlaylistAddeding: false,
        isPlaylistAdded: true,
        playlist: [...state.playlist, { ...action.payload, screenCheck: true }],
      };
    case GET_PLAYLIST_REQUEST:
      return {
        ...state,
        isPlaylistLoading: true,
      };
    case GET_PLAYLIST_SUCCESS:
      let temp = [];
      // console.log(action.payload);
      let payload = action.payload ?? [];
      if (payload == "") {
        payload=[];
      } else {
        payload?.forEach((p) => {
          temp.push({ ...p, screenCheck: true });
        });
      }
      return {
        ...state,
        isPlaylistLoading: false,
        playlist: temp,
      };

    case GET_FOLDER_PLAYLIST_SUCCESS:
      let _temp = [];
      // console.log(action.payload)
      action?.payload?.forEach((p) => {
        _temp.push({ ...p, screenCheck: true });
      });
      return {
        ...state,
        isPlaylistLoading: false,
        _playlist: _temp,
      };
    case GET_PLAYLIST_FAILED:
      return {
        ...state,
        isPlaylistLoading: false,
      };

    case ADD_CONTENT_VALUE:
      return {
        ...state,
        content: [...state.content, action.payload],
      };
    case DELETE_CONTENT_VALUE:
      return {
        ...state,
        content: state.content.filter((v) => v.id !== action.payload),
      };
    case ADD_CONTENT_IN_PLAYLIST:
      const addContentPlaylistIndex = state.playlist.findIndex(
        (p) => p._id === action.payload._id
      );
      state.playlist[addContentPlaylistIndex] = {
        ...action.payload,
        screenCheck: true,
      };
      return {
        ...state,
        isPlaylistUpdated: true,
        type: ADD_CONTENT_IN_PLAYLIST,
        playlist: state.playlist,
        _playlistId: action.payload._id,
      };
    case PLAYLIST_SCREEN_CHECK:
      const checkArrIndex = state.playlist.findIndex(
        (p) => p._id === action.payload._id
      );
      state.playlist[checkArrIndex] = { ...action.payload, screenCheck: false };
      return {
        ...state,
        playlist: state.playlist,
      };
    case DELETE_CONTENT_FROM_PLAYLIST:
      const deleteArrIndex = state.playlist.findIndex(
        (p) => p._id === action.payload.playlistId
      );
      state.playlist[deleteArrIndex] = {
        ...state.playlist[deleteArrIndex],
        content: state.playlist[deleteArrIndex].content.filter(
          (c) => c._id !== action.payload.contentId
        ),
      };
      return {
        ...state,
        isPlaylistUpdated: true,
        type: DELETE_CONTENT_FROM_PLAYLIST,
        playlist: state.playlist,
        _playlistId: action.payload.playlistId,
      };
    case DELETE_PLAYLIST:
      return {
        ...state,
        isPlaylistUpdated: true,
        type: DELETE_PLAYLIST,
        playlist: state.playlist.filter((p) => p._id !== action.payload._id),
      };
    case UPDATE_DURATION:
      const time = action.payload.time.split(":");
      const minmilli = time[0] * 1000 * 60;
      const secmilli = time[1] * 1000;

      const updateTimePlaylistIndex = state.playlist.findIndex(
        (p) => p._id === action.payload.playlistId
      );
      const updateTimeContentIndex = state.playlist[
        updateTimePlaylistIndex
      ].content.findIndex((c) => c._id === action.payload.contentId);

      const playlists = state.playlist[updateTimePlaylistIndex];
      playlists.content[updateTimeContentIndex] = {
        ...playlists.content[updateTimeContentIndex],
        duration: minmilli + secmilli,
      };

      state.playlist[updateTimePlaylistIndex] = { ...playlists };
      return {
        ...state,
        isPlaylistUpdated: true,
        type: UPDATE_DURATION,
        playlist: state.playlist,
        _playlistId: action.payload.playlistId,
      };
      case UPDATE_IMAGE_RESOLUTION:
        const idx=state.playlist.findIndex(p=>p._id==action.payload._id);
        state.playlist[idx]={...action.payload};
        return {
          ...state,
          isPlaylistUpdated: true,
          type: UPDATE_IMAGE_RESOLUTION,
          playlist: state.playlist,
          _playlistId: action.payload._id,
        }
    case PLAYLIST_SCHEDULE_SUCCESS:
      const findIndexOfPlaylist = state.playlist.findIndex(
        (p) => p._id === action.payload._id
      );
      state.playlist[findIndexOfPlaylist] = { ...action.payload };
      return {
        ...state,
        // isPlaylistScheduled: true,
        isPlaylistUpdated: true,
        type: PLAYLIST_SCHEDULE_SUCCESS,
        playlist: state.playlist,
        _playlistId: action.payload._id,
      };
    case PLAYLIST_SCHEDULE_DELETE:
      const _findIndexOfPlaylist = state.playlist.findIndex(
        (p) => p._id === action.payload._id
      );
      state.playlist[_findIndexOfPlaylist] = { ...action.payload };
      return {
        ...state,
        isPlaylistUpdated: true,
        type: PLAYLIST_SCHEDULE_DELETE,
        playlist: state.playlist,
        _playlistId: action.payload._id,
      };
    case CLIENT_PLAYLIST_SUCCESS:
      return {
        ...state,
        clientPlaylist: action.payload,
      };
    case PLAYLIST_CONTNET_SORTING:
      const _playlistIndex = state.playlist.findIndex(
        (p) => p._id == action.payload.playlistId
      );
      const _contentList = state.playlist[_playlistIndex].content;
      const _from = _contentList.findIndex((c) => c._id == action.payload.from);
      const _to = _contentList.findIndex((c) => c._id == action.payload.to);
      _contentList.splice(_to, 0, _contentList.splice(_from, 1)[0]);
      state.playlist[_playlistIndex] = {
        ...state.playlist[_playlistIndex],
        content: _contentList,
      };
      return {
        ...state,
        playlist: state.playlist,
        isPlaylistUpdated: true,
        type: PLAYLIST_CONTNET_SORTING,
        _playlistId: action.payload.playlistId,
      };
    case PLAYLIST_TO_MULTIPLE_SCREEN:
      return {
        ...state,
        isPlaylistAssign: true,
      };
    case PLAYLIST_TO_MULTIPLE_SCREEN_SUCCESS:
      const __playlistIndex = state.playlist.findIndex(
        (p) => p._id == action.payload._id
      );
      state.playlist[__playlistIndex] = { ...action.payload };
      return {
        ...state,
        playlist: state.playlist,
        type: PLAYLIST_TO_MULTIPLE_SCREEN_SUCCESS,
        _playlistId: action.payload._id,
        isPlaylistAssign: false,
        isPlaylistUpdated: true,
      };
    case PLAYLIST_UPDATE_REQUEST:
      return {
        ...state,
        isPlaylistPermissionUpdating:true
      }
      case PLAYLIST_PERMISSION_SUCCESS:
        const _perm_play_index=state.playlist.findIndex(p=>p._id==action.payload._id);
        state.playlist[_perm_play_index]=action.payload;
        return {
          ...state,
          playlist:state.playlist,
          type:PLAYLIST_PERMISSION_SUCCESS,
          isPlaylistPermissionUpdating:false,
          isPlaylistPermissionUpdated:true,
          isPlaylistUpdated:true
        }
    default:
      return state;
  }
};
